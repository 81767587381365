// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
export default function applyPrepend() {
  const arr = [
    Element.prototype,
    Document.prototype,
    DocumentFragment.prototype,
  ];

  arr.forEach(item => {
    if (Object.hasOwnProperty.call(item, 'prepend')) {
      return;
    }
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend(...args) {
        const argArr = Array.prototype.slice.call(args);
        const docFrag = document.createDocumentFragment();

        argArr.forEach(argItem => {
          const isNode = argItem instanceof Node;
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          );
        });

        this.insertBefore(docFrag, this.firstChild);
      },
    });
  });
}
