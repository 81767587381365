import {
  createPopperLite as createPopper,
  preventOverflow,
  flip,
} from '@popperjs/core';

export default class Tooltip {
  constructor(el) {
    this.popper = null;
    this.el = el;
  }

  init() {
    this.el.addEventListener('click', e => {
      e.preventDefault();
      this.show();
    });

    this.el.addEventListener('mouseenter', e => {
      e.preventDefault();
      this.show();
    });

    this.el.addEventListener('mouseleave', e => {
      e.preventDefault();
      this.hide();
    });
  }

  show() {
    if (this.popper) return;

    this.renderTooltip();
    document.body.appendChild(this.tooltip);

    this.popper = createPopper(this.el, this.tooltip, {
      placement: 'bottom-start',
      modifiers: [
        flip,
        preventOverflow,
        {
          name: 'preventOverflow',
          options: {
            padding: {
              top: 0,
              right: 16,
              bottom: 0,
              left: 0,
            },
          },
        },
      ],
    });

    this.closeHandler = e => {
      if (
        this.tooltip !== e.target &&
        !this.tooltip.contains(e.target) &&
        this.el !== e.target &&
        !this.el.contains(e.target)
      ) {
        this.hide();
      }
    };
    window.addEventListener('click', this.closeHandler);
    this.closeButton.addEventListener('click', e => {
      e.preventDefault();
      this.hide();
    });
  }

  hide() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }

    window.removeEventListener('click', this.closeHandler);
    this.tooltip.parentNode.removeChild(this.tooltip);
  }

  renderTooltip() {
    this.tooltip = document.createElement('div');
    this.tooltip.classList.add('tooltip-popup');

    if (this.el.dataset.tooltipTitle) {
      const tooltipHeader = document.createElement('div');
      tooltipHeader.classList.add('tooltip-popup__header');
      this.tooltip.appendChild(tooltipHeader);
      tooltipHeader.innerText = this.el.dataset.tooltipTitle || '';
    }

    const tooltipBody = document.createElement('div');
    tooltipBody.classList.add('tooltip-popup__body');
    this.tooltip.appendChild(tooltipBody);

    if (this.el.dataset.tooltipText) {
      tooltipBody.innerHTML = this.el.dataset.tooltipText;
    }

    this.closeButton = document.createElement('div');
    this.closeButton.classList.add('tooltip-popup__close-button');
    this.tooltip.appendChild(this.closeButton);
  }
}
