import { FormState, FORM_ERROR } from 'final-form'
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form'
import classNames from 'utils/preact/class-names'

import { checkEmail, checkPassword } from './services/validation'
import { useTranslation } from './translation'
import { profile } from './store'
import css from './style.module.scss'

interface FormValues {
  email: string
  password: string
}

export function Authentication({
  token,
  incentiveContainer,
  onAuthenticate,
  onPasswordForgot
}: {
  token: string
  incentiveContainer?: boolean
  onAuthenticate: () => void
  onPasswordForgot: () => void
}) {
  const t = useTranslation()

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: !incentiveContainer,
          [css['title-incentive']]: incentiveContainer,
          [css['spacer-bottom']]: true
        })}
      >
        {t('log_in')}
      </h2>

      <Form
        onSubmit={(values: FormValues): Promise<Object | undefined> =>
          fetch('/session', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRF-Token': token
            },
            credentials: 'include',
            body: JSON.stringify(values)
          })
            .then((res) => {
              if (!res.ok) {
                if (res.status !== 422 && res.status !== 401) {
                  return {
                    [FORM_ERROR]: `${res.status}: ${res.statusText}`
                  }
                }
              }

              return res.json()
            })
            .then((res) => {
              if (res.user) {
                profile.setKey('emailVerified', res.user.emailVerified)
                profile.setKey('premium', res.user.premium)
              }

              if (res.success) return undefined
              if (res.errors) return res.errors
              return res
            })
            .catch((err: Error) => ({
              [FORM_ERROR]: err.message
            }))
        }
      >
        {({
          hasValidationErrors,
          hasSubmitErrors,
          submitErrors,
          submitError,
          submitting,
          pristine,
          handleSubmit
        }: FormRenderProps & FormState<FormValues>) => (
          <form action="/session" method="post" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ submitSucceeded: true }}
              onChange={({
                submitSucceeded
              }: {
                values: FormValues
                submitSucceeded: boolean
              }) => {
                if (submitSucceeded) {
                  profile.setKey('authorized', true)
                  profile.setKey('reloadRequired', true)
                  onAuthenticate()
                }
              }}
            />
            <Field
              name="email"
              validate={(value) => checkEmail(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="email"
                >
                  <div>{t('your_email')}</div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="email"
                      type="email"
                      autoComplete="email"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />
            <Field
              name="password"
              validate={(value) => checkPassword(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="password"
                >
                  <div className={css['subscription-label']}>
                    <span className={css['subscription-name']}>{t('password')}</span>
                    <a
                      className={classNames({
                        [css['text-highlight']]: true,
                        [css['font-regular']]: true,
                        [css['text-underline']]: true
                      })}
                      href="/passwords/new"
                      onClick={(e) => {
                        e.preventDefault()
                        onPasswordForgot()
                      }}
                    >
                      {t('forgot_password')}
                    </a>
                  </div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="password"
                      type="password"
                      autoComplete="new-password"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitError || submitErrors}
              </p>
            )}

            <div className={css['spacer-bottom']} />

            <button
              className={classNames({
                [css.submit]: true,
                [css['is-loading']]: submitting
              })}
              type="submit"
              disabled={pristine || hasValidationErrors}
            >
              {t('log_in')}
            </button>
          </form>
        )}
      </Form>
    </div>
  )
}
