import throttle from 'utils/throttle';

const CLASS_NAME = 'carousel';

const isElementVisible = ({ el, viewportStart, viewportEnd }) => {
  if (!document.body.contains(el.el)) return false;
  if (el.width === 0) return false;

  /* if at least 80% of an element is in the viewport */
  if (
    el.left + (el.width - (80 * el.width) / 100) >= viewportStart &&
    el.left + (el.width - (20 * el.width) / 100) <= viewportEnd
  )
    return true;

  return false;
};

const createDot = ({ content, isActive }) => {
  const li = document.createElement('li');
  li.classList.add(`${CLASS_NAME}__dots-item`);
  if (isActive) li.classList.add('is-active');

  const anchor = document.createElement('a');
  anchor.classList.add(`${CLASS_NAME}__dot`);
  anchor.href = '#';
  anchor.appendChild(document.createTextNode(content));

  li.appendChild(anchor);

  return li;
};
class Carousel {
  constructor(el, { dots = true, buttons = true } = {}) {
    this.timeout = null;
    this.currentItems = [];

    if (dots) {
      this.dots = [];
      this.dotsList = el.querySelector('[data-carousel-dots]');
    }
    this.itemsList = el.querySelector('[data-carousel-list]');

    if (buttons) {
      this.buttons = true;
      this.buttonLeft = el.querySelector('[data-carousel-button-left]');
      this.buttonRight = el.querySelector('[data-carousel-button-right]');
    }

    const itemsListBox = this.itemsList.getBoundingClientRect();
    this.items = Array.from(this.itemsList.children).map((x, i) => {
      const itemBox = x.getBoundingClientRect();

      return {
        el: x,
        index: i,
        width: itemBox.width,
        left: Math.abs(itemBox.left - itemsListBox.left),
      };
    });
  }

  init() {
    const self = this;

    if (this.dots)
      this.dots = this.items.map((x, i) => {
        const dot = createDot({ content: i, isActive: x.isVisible });
        dot.addEventListener('click', e => {
          e.preventDefault();
          self.moveTo(x);
        });
        return this.dotsList.appendChild(dot);
      });

    this.itemsList.addEventListener(
      'scroll',
      throttle(() => {
        self.update();
        self.auto();
      }, 50)
    );

    /* TODO: change from throttle to debounce */
    window.addEventListener(
      'resize',
      throttle(() => {
        self.updateItems();
        self.update();
        self.auto();
      }, 1000)
    );

    if (this.buttons) {
      this.buttonLeft.addEventListener('click', e => {
        e.preventDefault();
        self.move('left');
      });

      this.buttonRight.addEventListener('click', e => {
        e.preventDefault();
        self.move('right');
      });
    }

    this.scrollListener = throttle(() => {
      this.loadMapIfInViewport();
    }, 500);

    window.addEventListener('scroll', this.scrollListener);
    this.loadMapIfInViewport();

    this.update();
    this.auto();

    return this;
  }

  auto() {
    if (this.timeout === null) {
      this.timeout = setTimeout(() => {
        this.timeout = null;

        this.move('right');
        this.auto();
      }, 10000);

      return this;
    }

    clearTimeout(this.timeout);
    this.timeout = null;
    this.auto();

    return this;
  }

  move(direction) {
    if (this.items.length === 0) return this;
    if (this.currentItems.length === 0) return this;

    let nextPosition = 0;

    if (direction === 'left') {
      // if the first element is already visible
      if (this.items[0].isVisible)
        nextPosition = this.items[this.items.length - 1].left;
      else {
        // get the item before the first visible
        const beforeFirstVisible = this.items[this.currentItems[0].index - 1];
        nextPosition = beforeFirstVisible.left;
      }
    }

    if (direction === 'right') {
      // if the last element is already visible
      if (this.items[this.items.length - 1].isVisible)
        nextPosition = this.items[0].left;
      else {
        // get the item after the first visible
        const afterFirstVisible = this.items[this.currentItems[0].index + 1];
        nextPosition = afterFirstVisible.left;
      }
    }
    this.itemsList.scrollLeft = nextPosition;

    return this;
  }

  moveTo(el) {
    // TODO: come up with a better logic to show the element on the centre of the viewport.
    this.itemsList.scrollLeft = el.left;

    return this;
  }

  update() {
    this.checkItemsVisibility();
    this.currentItems = this.items.filter(x => x.isVisible);

    if (this.dots)
      this.items.forEach((x, i) => {
        if (x.isVisible) this.dots[i].classList.add('is-active');
        else this.dots[i].classList.remove('is-active');
      });

    if (this.currentItems.length === this.items.length) {
      if (this.buttons) {
        this.buttonLeft.classList.add('is-hidden');
        this.buttonRight.classList.add('is-hidden');
      }

      if (this.dots) this.dotsList.classList.add('is-hidden');
    } else {
      if (this.buttons) {
        this.buttonLeft.classList.remove('is-hidden');
        this.buttonRight.classList.remove('is-hidden');
      }
      if (this.dots) this.dotsList.classList.remove('is-hidden');
    }

    return this;
  }

  updateItems() {
    const itemsListBox = this.itemsList.getBoundingClientRect();
    this.items = Array.from(this.itemsList.children).map((x, i) => {
      const itemBox = x.getBoundingClientRect();

      return {
        el: x,
        index: i,
        width: itemBox.width,
        left: Math.abs(itemBox.left - itemsListBox.left),
      };
    });

    return this;
  }

  checkItemsVisibility() {
    const listWidth = this.itemsList.getBoundingClientRect().width;
    const { scrollLeft: viewportStart } = this.itemsList;
    const viewportEnd = listWidth + viewportStart;

    this.items = this.items.map(x => ({
      ...x,
      isVisible: isElementVisible({ el: x, viewportStart, viewportEnd }),
    }));

    return this;
  }

  loadMapIfInViewport() {
    if (!this.inViewport()) {
      return;
    }

    if (this.scrollListener) {
      window.removeEventListener('scroll', this.scrollListener);
      this.scrollListener = undefined;
    }

    this.items.forEach(item => {
      const image = new Image();
      image.src = item.el.dataset.src;
      image.alt = item.el.dataset.alt;
      image.addEventListener('load', () => {
        item.el.appendChild(image);
        item.el.classList.remove(`${CLASS_NAME}__list-item--loading`);
      });
    });
  }

  inViewport() {
    const bounding = this.itemsList.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const advance = 200;

    return (
      (bounding.top >= 0 && bounding.top - advance <= viewportHeight) ||
      (bounding.bottom + advance >= 0 && bounding.top <= viewportHeight)
    );
  }
}

export default Carousel;
