function toFmt(val) {
  if (val >= 0 && val <= 9) {
    return `0${val}`;
  }
  return val;
}

export function format12Hr(localDate, withSeconds = true) {
  let hours = localDate.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  let timeStr = `${hours}:${toFmt(localDate.getMinutes())}`;
  if (withSeconds) {
    timeStr += `:${toFmt(localDate.getSeconds())}`;
  }
  timeStr += ` ${ampm}`;
  return timeStr;
}

export function formatDelta(delta, { withSeconds = true, withSpace = true } = {}) {
  let deltaStr = '';
  const sep = withSpace ? ' ' : '';

  if (Math.floor(delta / 3600) > 0) {
    deltaStr = `${Math.floor(delta / 3600)}${sep}hr `;
  }

  if (Math.floor((delta % 3600) / 60) > 0) {
    deltaStr += `${Math.floor((delta % 3600) / 60)}${sep}min `;
  }

  if (withSeconds && toFmt(delta % 60)) {
    deltaStr += `${toFmt(Math.floor(delta % 60))}${sep}s`;
  }

  return deltaStr;
}
