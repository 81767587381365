import { ComponentChildren } from 'preact'

import css from './style.module.scss'
import xmark from './static/xmark.svg'
import caretLeft from './static/caret-left.svg'

export function WrapperHeader({
  children,
  tooltip,
  onBack,
  onClose
}: {
  children: ComponentChildren
  tooltip?: string
  onBack?: () => void
  onClose?: () => void
}) {
  return (
    <div className={css.container}>
      <header className={css.header}>
        <div className={css['header-start']}>
          {onBack !== undefined && (
            <button type="button" className={css['back-button']} onClick={onBack}>
              <img src={caretLeft} alt="Back" width={12} />
            </button>
          )}
        </div>
        <div className={css['header-end']}>
          {tooltip && <span className={css.tooltip}>{tooltip}</span>}
          {onClose !== undefined && typeof onClose === 'function' && (
            <button type="button" className={css['close-button']} onClick={onClose}>
              <img
                className={css['close-icon']}
                src={xmark}
                alt="Close this popup"
                width={14}
                height={22}
              />
            </button>
          )}
        </div>
      </header>
      {children}
    </div>
  )
}
