import './index.scss'

declare global {
  interface Window {
    grid_multi?: string[][]
    grid_multi_keys?: string[]
    places_multi_keys?: string[]
    places_multi?: string[][]
  }
}

async function loadBreaksFromOldMap(mapFilename: string): Promise<any> {
  const loader = new Promise((resolve) => {
    const scriptTag = document.createElement('script')
    scriptTag.async = true
    scriptTag.type = 'text/javascript'
    scriptTag.src = `/maps/bathymetric/${mapFilename}.breaks.forecast.js`

    scriptTag.onload = () => {
      resolve(this)
    }
    document.head.appendChild(scriptTag)
  })

  return loader
}

export function MapNodesDebug() {
  const table = document.querySelector('.debug-table--old-map') as HTMLElement

  if (!table) return

  const mapFilename = table.dataset.map
  const locationFilename = table.dataset.location
  const lastRow = table.querySelector('tr:last-child')

  loadBreaksFromOldMap(mapFilename).then(() => {
    const index = window.places_multi_keys.findIndex((t) => t[0] === locationFilename)
    if (!index) {
      const cell = lastRow.firstChild as HTMLTableCellElement
      cell.innerText = 'no data for this location'
      return
    }
    lastRow.parentNode.removeChild(lastRow)

    const ratings = window.places_multi.map((row) => parseInt(row[index][1], 10))

    const row = document.createElement('tr') as HTMLTableRowElement
    let col: HTMLTableCellElement

    for (let r = 0; r < 60; r += 1) {
      col = document.createElement('td')
      col.innerText = `${ratings[r]}`
      row.appendChild(col)
    }

    table.appendChild(row)
  })
}
