import { useStore } from '@nanostores/preact'
import { useEffect, useState } from 'preact/hooks'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Stripe, PaymentRequest } from '@stripe/stripe-js'
import {
  Elements,
  PaymentRequestButtonElement,
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import classNames from 'utils/preact/class-names'

import { Currency, IsoCountry, Price, Products } from './support'
import { profile } from './store'
import { useTranslation } from './translation'

import css from './style.module.scss'
import cards from './static/cards.png'
import surfImageJPG from './static/surf_image_1.jpg'
import surfImageWEBP from './static/surf_image_1.webp'

function pay(
  url: string,
  token: string,
  body: string
): Promise<undefined | Record<string, string>> {
  return fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': token
    },
    credentials: 'include',
    body
  })
    .then((res) => {
      if (!res.ok && res.status !== 422 && res.status !== 401) {
        return {
          base: `${res.status}: ${res.statusText}`
        }
      }

      return res.json()
    })
    .then((res) => {
      if (res.success) return undefined
      if (res.errors) return res.errors
      return res
    })
    .catch((err: Error) => ({
      base: err.message
    }))
}

function PaymentForm({
  price,
  country,
  currency,
  token,
  onPay
}: {
  price: Price
  country: IsoCountry
  currency: Currency
  token: string
  onPay: () => void
}) {
  const stripe = useStripe()
  const elements = useElements()
  const user = useStore(profile)
  const t = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)

  useEffect(() => {
    if (stripe) {
      ;(async () => {
        const pr = stripe.paymentRequest({
          country: country.iso.toUpperCase(),
          currency: currency.acronym.toLowerCase(),
          total: {
            label: `Support ${user.subscription}`,
            amount: price.unit_amount
          },
          requestPayerName: true,
          requestPayerEmail: true
        })
        const canMakePayment = await pr.canMakePayment()
        if (canMakePayment) {
          setPaymentRequest(pr)
        }
      })()
    }
  }, [stripe, country, currency, price, user.subscription])

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', async (paymentResponse) => {
        setIsLoading(true)
        setSubmitError('')

        const result = await pay(
          '/sign_up/stripe_payment',
          token,
          JSON.stringify({
            stripe_payment: {
              price_id: price.price_id
            },
            source: user.source
          })
        )

        if (result && result.clientSecret) {
          const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
            result.clientSecret,
            { payment_method: paymentResponse.paymentMethod.id },
            { handleActions: false }
          )

          if (confirmError) {
            // eslint-disable-next-line no-console
            console.error(confirmError)

            setIsLoading(false)
            setSubmitError(confirmError.message)
            paymentResponse.complete('fail')
            return
          }

          paymentResponse.complete('success')

          if (paymentIntent.status === 'requires_action') {
            const { error } = await stripe.confirmCardPayment(result.clientSecret)
            if (error) {
              // eslint-disable-next-line no-console
              console.error(error)

              setIsLoading(false)
              setSubmitError(error.message)
              return
            }
          }

          profile.setKey('premium', true)
          profile.setKey('reloadRequired', true)
          onPay()
        } else if (result && result.base) setSubmitError(result.base)
        else setSubmitError('Something went wrong')

        setIsLoading(false)
      })
    }

    return () => {
      if (paymentRequest) paymentRequest.off('paymentmethod')
    }
  }, [stripe, paymentRequest, token, price, onPay, user])

  const handleSubmit = async (event: Event) => {
    event.preventDefault()

    setIsLoading(true)
    setSubmitError('')

    const response = await pay(
      '/sign_up/stripe_payment',
      token,
      JSON.stringify({
        stripe_payment: {
          price_id: price.price_id
        },
        source: user.source
      })
    )

    if (response && response.clientSecret) {
      const { paymentIntent, error } = await stripe.confirmCardPayment(response.clientSecret, {
        payment_method: {
          card: elements.getElement('card')
        }
      })

      if (error) {
        // eslint-disable-next-line no-console
        console.error('failed while paying using filled in card details', error)
        setSubmitError(error.message)
      } else if (paymentIntent.status === 'succeeded') {
        profile.setKey('premium', true)
        profile.setKey('reloadRequired', true)
        onPay()
      } else {
        setSubmitError(`Something went wrong.\n(paymentIntent.status is ${paymentIntent.status})`)
      }
    } else if (response && response.base) {
      setSubmitError(response.base)
    } else {
      setSubmitError('Something went wrong')
    }

    setIsLoading(false)
  }

  return (
    <form action="/sign_up/stripe_payment" method="post" onSubmit={handleSubmit}>
      {paymentRequest && (
        <div className={css['spacer-bottom']}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}
      <img
        src={cards}
        alt="Payment providers"
        width={201}
        height={18.5}
        className={css['spacer-bottom']}
      />
      <div className={css['card-wrapper']}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                lineHeight: '1.5'
              }
            }
          }}
        />
      </div>
      <div className={css['spacer-bottom']} />
      {submitError && (
        <p
          className={classNames({
            [css['text-medium']]: true,
            [css['text-center']]: true,
            [css['error-highlight']]: true
          })}
        >
          {submitError}
        </p>
      )}
      <button
        className={classNames({
          [css.submit]: true,
          [css['is-loading']]: isLoading
        })}
        type="submit"
      >
        {t('pay_now')}
      </button>
    </form>
  )
}

export function Payment({
  token,
  incentiveContainer,
  products,
  currencies,
  isoCountries,
  publishableKey,
  onPay
}: {
  token: string
  incentiveContainer?: boolean
  products: Products
  currencies: Currency[]
  isoCountries: IsoCountry[]
  publishableKey: string
  onPay: () => void
}) {
  const [stripe, setStripe] = useState<Stripe | null>(null)

  useEffect(() => {
    ;(async () => {
      const resolved = await loadStripe(publishableKey)
      setStripe(resolved)
    })()
  }, [publishableKey])

  const t = useTranslation()
  const user = useStore(profile)
  const country = isoCountries.find((c) => c.iso === user.countryIso)
  const currency = currencies.find((c) => c.acronym === country.currency)
  const price = products[user.subscription].prices[currency.acronym]

  return (
    <div className={classNames({ [css['split-container']]: !incentiveContainer })}>
      <div className={classNames({ [css['flex-grow']]: !incentiveContainer })}>
        <div className={classNames({ [css.content]: true, [css['not-growable']]: true })}>
          <h2
            className={classNames({
              [css.title]: !incentiveContainer,
              [css['title-incentive']]: incentiveContainer,
              [css['spacer-bottom']]: true
            })}
          >
            {t('youre_awesome')}
          </h2>

          <p
            className={classNames({
              [css['text-regular']]: incentiveContainer,
              [css['text-medium']]: !incentiveContainer
            })}
          >
            {t('your_contribution')}
          </p>
          <div
            className={classNames({
              [css['is-active']]: true,
              [css['radio-label']]: true,
              [css['radio-label-primary']]: true
            })}
          >
            <div className={css['subscription-label']}>
              <span className={css['subscription-name']}>
                {t(`support_${user.subscription}`)}
              </span>
              <span className={css['subscription-price']}>
                {currency.prefix}&thinsp;{price.unit_amount / 100}
              </span>
            </div>
          </div>
        </div>
        <hr className={css.divider} />
        <div className={css.content}>
          {stripe ? (
            <Elements stripe={stripe}>
              <PaymentForm
                token={token}
                price={price}
                country={country}
                currency={currency}
                onPay={onPay}
              />
            </Elements>
          ) : (
            <div className={css['text-center']}>
              <div className={css.loader} />
              <div className={css['text-medium']}>Loading payment module...</div>
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames({
          [css['flex-grow']]: !incentiveContainer,
          [css['incentive-hidden']]: incentiveContainer
        })}
      >
        <picture>
          <source srcSet={surfImageWEBP} type="image/webp" />
          <source srcSet={surfImageJPG} type="image/jpeg" />
          <img
            src={surfImageJPG}
            alt="Surfer"
            className={classNames({
              [css['large-up-shown']]: true,
              [css['surf-image']]: true
            })}
          />
        </picture>
      </div>
    </div>
  )
}
