import { FormState, FORM_ERROR } from 'final-form'
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form'
import classNames from 'utils/preact/class-names'

import { checkEmail } from './services/validation'
import { useTranslation } from './translation'
import css from './style.module.scss'

export function PasswordReset({
  token,
  incentiveContainer,
  onPasswordReset
}: {
  token: string
  incentiveContainer?: boolean
  onPasswordReset: () => void
}) {
  const t = useTranslation()

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: !incentiveContainer,
          [css['title-incentive']]: incentiveContainer,
          [css['spacer-bottom']]: true
        })}
      >
        {t('reset_your_password')}
      </h2>
      <p
        className={classNames({
          [css['text-small']]: true,
          [css['font-regular']]: true
        })}
      >
        {' '}
        {t('we_will_email')}
      </p>
      <Form
        onSubmit={({ email }: { email: string }): Promise<Object | undefined> =>
          fetch('/sign_up/password/forgot', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRF-Token': token
            },
            credentials: 'include',
            body: JSON.stringify({ user: { email } })
          })
            .then((res) => {
              if (!res.ok) {
                if (res.status !== 422 && res.status !== 401) {
                  return {
                    [FORM_ERROR]: `${res.status}: ${res.statusText}`
                  }
                }
              }

              return res.json()
            })
            .then((res) => {
              if (res.success) return undefined
              if (res.errors) return res.errors
              return res
            })
            .catch((err: Error) => ({
              [FORM_ERROR]: err.message
            }))
        }
      >
        {({
          hasValidationErrors,
          hasSubmitErrors,
          submitErrors,
          submitError,
          submitting,
          pristine,
          handleSubmit
        }: FormRenderProps & FormState<{ email: string }>) => (
          <form action="/sign_up/password/forgot" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ submitSucceeded: true }}
              onChange={({ submitSucceeded }: { submitSucceeded: boolean }) => {
                if (submitSucceeded) {
                  onPasswordReset()
                }
              }}
            />
            <Field
              name="email"
              validate={(value) => checkEmail(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="email"
                >
                  <div>{t('email')}</div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="email"
                      type="email"
                      autoComplete="email"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['text-center']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitErrors.base || submitError}
              </p>
            )}

            <button
              className={classNames({
                [css.submit]: true,
                [css['is-loading']]: submitting
              })}
              type="submit"
              disabled={pristine || hasValidationErrors}
            >
              {t('reset_password')}
            </button>
          </form>
        )}
      </Form>
    </div>
  )
}
