import classNames from 'utils/preact/class-names'
import css from './style.module.scss'

export function LoadingError({ message, onReload }: { message: string; onReload: () => void }) {
  return (
    <>
      <div className={css.content} />
      <h1
        className={classNames({
          [css.title]: true,
          [css['text-medium']]: true,
          [css['text-center']]: true,
          [css['spacer-bottom']]: true
        })}
      >
        Something went wrong
      </h1>
      <div
        className={classNames({
          [css['text-medium']]: true,
          [css['text-center']]: true,
          [css['spacer-bottom']]: true,
          [css['error-highlight']]: true
        })}
      >
        {message}
      </div>
      <p
        className={classNames({
          [css['text-medium']]: true,
          [css['text-center']]: true
        })}
      >
        Please, try again or reload the page
      </p>
      <div className={css.content}>
        <button type="button" className={css.submit} onClick={onReload}>
          Try Again
        </button>
      </div>
    </>
  )
}
