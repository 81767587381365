import { useStore } from '@nanostores/preact'
import { FormState, FORM_ERROR } from 'final-form'
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form'
import classNames from 'utils/preact/class-names'

import { send } from './services/backend'
import { checkEmail } from './services/validation'
import { useTranslation } from './translation'
import { profile } from './store'
import css from './style.module.scss'

interface FormValues {
  email: string
}

export function EmailUpdate({
  token,
  onEmailUpdated,
  incentiveContainer
}: {
  token: string
  incentiveContainer?: boolean
  onEmailUpdated: () => void
}) {
  const t = useTranslation()
  const user = useStore(profile)

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: !incentiveContainer,
          [css['title-incentive']]: incentiveContainer,
          [css['spacer-bottom']]: true
        })}
      >
        {t('update_your_email')}
      </h2>
      <Form
        onSubmit={(values: FormValues): Promise<Object | undefined> =>
          send<FormValues>({
            token,
            url: '/profile',
            method: 'put',
            body: JSON.stringify({ user: { email: values.email }, source: user.source })
          }).then((result) => {
            if (result.success) return undefined
            if (result.error) return { [FORM_ERROR]: result.error }
            return result.errors
          })
        }
      >
        {({
          handleSubmit,
          submitting,
          hasSubmitErrors,
          submitError,
          values
        }: FormRenderProps & FormState<FormValues>) => (
          <form action="/profile" method="put" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ submitSucceeded: true }}
              onChange={({ submitSucceeded }: { submitSucceeded: boolean }) => {
                if (submitSucceeded) {
                  profile.setKey('email', values.email)
                  onEmailUpdated()
                }
              }}
            />
            <Field
              name="email"
              validate={(value) => checkEmail(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['spacer-bottom']]: true
                  })}
                  htmlFor="email"
                >
                  <div>{t('your_email')}</div>
                  <input
                    {...input}
                    id="email"
                    type="text"
                    autoComplete="email"
                    placeholder={t('enter_your_email_here')}
                    className={css.input}
                  />
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />
            <button
              className={classNames({
                [css.submit]: true,
                [css['spacer-bottom']]: true,
                [css['is-loading']]: submitting
              })}
              type="submit"
            >
              {t('next')}
            </button>

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['text-center']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitError}
              </p>
            )}
          </form>
        )}
      </Form>
    </div>
  )
}
