// append a new star rating by using: createStarRating({ size: X, rating: X })
import './index.scss'

require.context('./static', true, /\.svg$/)
interface StarRatingOptions {
  size?: number
  rating: number
  windState?: string
}

const WHITE_STARS = ['on', 'cross-on', 'cross-<br/>on', 'cross-<BR>on', 'cross']

export function starIsWhite(windState: string) {
  return WHITE_STARS.includes(windState)
}

export default function createStarRating({
  size = 32,
  rating,
  windState
}: StarRatingOptions): SVGElement {
  const starSize = { width: size, height: size }
  let color: string = 'hsl(0, 0%, 100%)'
  let stroke: number = 1
  let weight: number = 400
  const scale = rating < 6 ? 0.8 : 1
  const translate = rating < 6 ? 4 : 0
  const dangerous = rating > 10
  const textColor = dangerous ? 'hsl(0, 0%, 100%)' : 'hsl(0, 0%, 0%)'
  const starText = dangerous ? '!' : rating
  const whiteStar = starIsWhite(windState)

  switch (rating) {
    case 1:
      color = 'hsl(57.1, 100%, 79.4%)'
      break
    case 2:
      color = 'hsl(54.7, 100%, 77.8%)'
      break
    case 3:
      color = 'hsl(53.3, 100%, 73.5%)'
      break
    case 4:
      color = 'hsl(52, 100%, 70.6%)'
      break
    case 5:
      color = 'hsl(50.9, 100%, 67.6%)'
      weight = 600
      break
    case 6:
      color = 'hsl(50, 100%, 64.7%)'
      weight = 600
      break
    case 7:
      color = 'hsl(49.2, 100%, 61.8%)'
      stroke = 2
      weight = 600
      break
    case 8:
      color = 'hsl(49.2, 100%, 61.8%)'
      stroke = 2
      weight = 800
      break
    case 9:
      color = 'hsl(48, 100%, 55.9%)'
      stroke = 2
      weight = 800
      break
    case 10:
      color = 'hsl(47.5, 100%, 52.9%)'
      stroke = 0
      weight = 800
      break
    case 11:
      color = 'hsl(0, 100%, 40.8%)'
      weight = 800
      break
    default:
      color = 'hsl(0, 0%, 100%)'
      weight = 200
      break
  }

  color = whiteStar && rating <= 10 ? 'hsl(0, 0%, 100%)' : color

  const star = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  star.setAttribute('viewBox', '0 0 42 42')
  star.setAttribute('width', `${starSize.width}`)
  star.setAttribute('height', `${starSize.height}`)

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  if (dangerous) {
    path.setAttribute('d', 'm21 1.63 10.5 18.18 10.5 18.19h-21-21l10.5-18.19z')
    path.setAttribute('fill', color)
    path.setAttribute('transform', 'scale(0.8)')
  } else {
    path.setAttribute(
      'd',
      'm21 1.06 6.49 13.14 14.51 2.11-10.5 10.24 2.48 14.45-12.98-6.82-12.98 6.82 2.48-14.45-10.5-10.24 14.51-2.11z'
    )
    path.setAttribute('fill', color)
    path.setAttribute('stroke', 'hsl(0, 0%, 0%)')
    path.setAttribute('stroke-width', `${stroke}px`)
    path.setAttribute('transform', `translate(${translate}, ${translate}) scale(${scale})`)
  }
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  text.setAttribute('x', '50%')
  text.setAttribute('y', '60%')
  text.setAttribute('font-size', '20')
  text.setAttribute('font-weight', `${weight}`)
  text.setAttribute('fill', `${textColor}`)
  text.setAttribute('dominant-baseline', 'middle')
  text.setAttribute('text-anchor', 'middle')
  text.textContent = `${starText}`
  text.setAttribute('font-size', '20')

  if (dangerous) {
    text.setAttribute('transform', 'scale(0.8)')
  } else {
    text.setAttribute('transform', `translate(${translate}, ${translate}) scale(${scale})`)
  }

  star.appendChild(path)
  star.appendChild(text)

  return star
}
