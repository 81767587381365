import { JSXInternal } from 'preact/src/jsx'
import { useEffect, useState } from 'preact/hooks'

import { profile } from './store'
import { TranslationProvider } from './translation'
import { getSupport } from './services/backend'
import { LoadingError } from './loading-error'
import { Loader } from './loader'
import { Support } from './support'
import { Payment } from './payment'
import { IncentiveAuthorization } from './incentive-authorization'
import { IncentiveSubscriptions } from './incentive-subscriptions'
import { WelcomePaid } from './welcome-paid'
import { WelcomeFree } from './welcome-free'
import { EmailVerify } from './email-verify'
import { EmailUpdate } from './email-update'
import { WrapperHeader } from './wrapper-header'
import { Authentication } from './authentication'
import { PasswordReset } from './password-reset'
import { EmailSent } from './email-sent'
import { Pages } from './pages'
import css from './style.module.scss'

export function Incentive({
  token,
  onClose,
  onReload,
  incentiveContainer
}: {
  token: string
  onClose: () => void
  onReload: () => void
  incentiveContainer?: boolean
}) {
  const [annoyed, setAnnoyed] = useState(false)
  const [page, setPage] = useState<Pages>(Pages.LoaderPage)
  const [support, setSupport] = useState<Support | null>(null)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setTimeout(() => setAnnoyed(true), 1000 * 10)
  }, [])

  useEffect(() => {
    setErrorMessage('')
    ;(async () => {
      let data: Support
      try {
        data = await getSupport()
      } catch (error) {
        let msg = 'fetching support, but the error is unknown'
        if (error instanceof Error) msg = error.message

        setPage(Pages.LoadingErrorPage)
        setErrorMessage(msg)

        return
      }

      setSupport(data)
      profile.setKey('source', 'incentive')
      profile.setKey('countryIso', data.default_iso_country)
      if (data.user.signedIn) {
        if (data.user.emailVerified) {
          setPage(Pages.SubscriptionsPage)
        } else {
          setPage(Pages.EmailVerifyPage)
        }
      } else {
        setPage(Pages.AuthorizationPage)
      }
    })()
  }, [])

  const closePage = () => {
    if (profile.get().reloadRequired) {
      window.location.reload()
    } else {
      onClose()
    }
  }

  let el: JSXInternal.Element
  switch (page) {
    case Pages.LoaderPage:
      el = (
        <div>
          <Loader />
        </div>
      )
      break
    case Pages.LoadingErrorPage:
      el = (
        <div>
          <LoadingError message={errorMessage} onReload={onReload} />
        </div>
      )
      break
    case Pages.AuthorizationPage:
      el = (
        <IncentiveAuthorization
          token={token}
          isoCountries={support.iso_countries}
          onAuthenticate={() => setPage(Pages.AuthenticationPage)}
          onAuthorization={() => setPage(Pages.SubscriptionsPage)}
        />
      )
      break
    case Pages.AuthenticationPage:
      el = (
        <WrapperHeader onBack={() => setPage(Pages.AuthorizationPage)}>
          <Authentication
            token={token}
            incentiveContainer={incentiveContainer}
            onAuthenticate={() => {
              if (!profile.get().emailVerified) {
                setPage(Pages.EmailVerifyPage)
                return
              }
              window.location.reload()
            }}
            onPasswordForgot={() => setPage(Pages.PasswordResetPage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.SubscriptionsPage:
      el = (
        <WrapperHeader onBack={() => setPage(Pages.AuthorizationPage)}>
          <IncentiveSubscriptions
            token={token}
            products={support.products}
            currencies={support.currencies}
            isoCountries={support.iso_countries}
            onFree={() => setPage(Pages.EmailVerifyPage)}
            onPaid={() => setPage(Pages.PaymentPage)}
            experiment={support.experiment}
          />
        </WrapperHeader>
      )
      break
    case Pages.PaymentPage:
      el = (
        <WrapperHeader onBack={() => setPage(Pages.SubscriptionsPage)}>
          <div style={{ width: '100%' }}>
            <Payment
              token={token}
              incentiveContainer={incentiveContainer}
              products={support.products}
              currencies={support.currencies}
              isoCountries={support.iso_countries}
              publishableKey={support.stripe_publishable_key}
              onPay={() => setPage(Pages.EmailVerifyPage)}
            />
          </div>
        </WrapperHeader>
      )
      break
    case Pages.EmailVerifyPage: {
      const shouldBack = profile.get().newAccountCreated && profile.get().subscription === 'free'
      el = (
        <WrapperHeader
          onClose={annoyed && closePage}
          onBack={shouldBack ? () => setPage(Pages.SubscriptionsPage) : undefined}
        >
          <EmailVerify
            token={token}
            incentiveContainer={incentiveContainer}
            onVerify={() => {
              if (profile.get().premium) {
                setPage(Pages.WelcomePaidPage)
                return
              }

              setPage(Pages.WelcomeFreePage)
            }}
            onEmailUpdate={() => setPage(Pages.EmailUpdatePage)}
          />
        </WrapperHeader>
      )
      break
    }
    case Pages.EmailUpdatePage:
      el = (
        <WrapperHeader onBack={() => setPage(Pages.EmailVerifyPage)}>
          <EmailUpdate
            token={token}
            incentiveContainer={incentiveContainer}
            onEmailUpdated={() => setPage(Pages.EmailVerifyPage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.WelcomePaidPage:
      el = (
        <WelcomePaid
          incentiveContainer={incentiveContainer}
          onClose={() => window.location.reload()}
        />
      )
      break
    case Pages.WelcomeFreePage:
      el = (
        <WelcomeFree
          incentiveContainer={incentiveContainer}
          onClose={() => window.location.reload()}
        />
      )
      break
    case Pages.PasswordResetPage:
      el = (
        <WrapperHeader onBack={() => setPage(Pages.AuthenticationPage)}>
          <PasswordReset
            token={token}
            incentiveContainer={incentiveContainer}
            onPasswordReset={() => setPage(Pages.EmailSentPage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.EmailSentPage:
      el = <EmailSent incentiveContainer={incentiveContainer} modal={false} />
      break
    default:
      throw new Error(`Unexpected error. Specified page (${page}) is not supported.`)
  }

  return (
    <TranslationProvider definitions={support?.messages}>
      <div className={css.incentive}>{el}</div>
    </TranslationProvider>
  )
}
