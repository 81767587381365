import './index.scss';
import './static/wave-background.svg';
import './static/icon-gloves.svg';
import './static/icon-boots.svg';
import './static/icon-hood.svg';
import './static/icon-wetsuit--boardshorts.svg';
import './static/icon-wetsuit--rashvest.svg';
import './static/icon-wetsuit--shorty.svg';
import './static/icon-wetsuit--spring.svg';
import './static/icon-wetsuit--winter.svg';
import './static/icon-avatar.svg';
import './static/best-surf-background.jpg';
import TideClock from './tide-clock';

const tideClockEl = document.querySelector('.guide-page__tide-clock');
if (tideClockEl) {
  const tideClock = new TideClock(tideClockEl);
  tideClock.init();
}
