import { useStore } from '@nanostores/preact'
import { useRef, useState, useMemo } from 'preact/hooks'
import { FormApi, FormState, FORM_ERROR } from 'final-form'
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form'
import classNames from 'utils/preact/class-names'

import { checkEmail, checkPassword } from './services/validation'
import { useTranslation } from './translation'
import { profile, SubscriptionValue } from './store'
import { FocusFirstError } from './focus-first-error'
import { Currency, IsoCountry, ProductDuration, Products } from './support'
import css from './style.module.scss'

interface FormValues {
  email: string
  password: string
  subscription: SubscriptionValue
}

export function AuthenticationSubscribe({
  token,
  products,
  currencies,
  isoCountries,
  onAuthenticate,
  onPasswordForgot,
  onAuthenticatedContribute,
  onUnathenticatedContribute
}: {
  token: string
  products: Products
  currencies: Currency[]
  isoCountries: IsoCountry[]
  onAuthenticate: () => void
  onPasswordForgot: () => void
  onAuthenticatedContribute: (subscription: SubscriptionValue) => void
  onUnathenticatedContribute: (subscription: SubscriptionValue) => void
}) {
  const t = useTranslation()
  const user = useStore(profile)
  const [isContributed, setContribute] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const country = isoCountries.find((c) => c.iso === user.countryIso)
  const currency = currencies.find((c) => c.acronym === country.currency)

  const formRef = useRef<HTMLFormElement>(null)
  const decorator = useMemo(() => FocusFirstError(formRef), [])

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: true,
          [css['spacer-bottom']]: true
        })}
      >
        {t('log_in')}
      </h2>

      <Form
        initialValues={{ subscription: user.subscription }}
        decorators={[decorator]}
        onSubmit={(values: FormValues): Promise<Object | undefined> =>
          fetch('/session', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRF-Token': token
            },
            credentials: 'include',
            body: JSON.stringify(values)
          })
            .then((res) => {
              if (!res.ok) {
                if (res.status !== 422 && res.status !== 401) {
                  return {
                    [FORM_ERROR]: `${res.status}: ${res.statusText}`
                  }
                }
              }

              return res.json()
            })
            .then((res) => {
              if (res.user) {
                if (res.user.paymentProfile) {
                  profile.setKey('paymentProfile', res.user.paymentProfile)
                }
                profile.setKey('emailVerified', res.user.emailVerified)
                profile.setKey('premium', res.user.premium)
              }

              if (res.success) return undefined
              if (res.errors) return res.errors
              return res
            })
            .catch((err: Error) => ({
              [FORM_ERROR]: err.message
            }))
        }
      >
        {({
          hasValidationErrors,
          hasSubmitErrors,
          submitErrors,
          submitError,
          submitting,
          pristine,
          form,
          handleSubmit
        }: FormRenderProps & FormState<FormValues>) => (
          <form
            action="/session"
            method="post"
            onSubmit={(event: Event) => {
              // assume the function was called by pressing enter, mobile next button, or 'log in' button
              setContribute(false)
              handleSubmit(event)
            }}
            ref={formRef}
          >
            <FormSpy
              subscription={{ pristine: true }}
              onChange={() => {
                if (!isInitialized) {
                  form.batch(() => {
                    form.change('email', user.email)
                    form.change('subscription', user.subscription)
                  })
                  setIsInitialized(true)
                }
              }}
            />
            <FormSpy
              subscription={{ submitSucceeded: true, values: true }}
              onChange={({
                submitSucceeded,
                values
              }: {
                submitSucceeded: boolean
                values: FormValues
              }) => {
                if (!submitSucceeded) return

                profile.setKey('email', values.email)
                profile.setKey('subscription', values.subscription)
                profile.setKey('reloadRequired', true)

                if (isContributed) onAuthenticatedContribute(values.subscription)
                else onAuthenticate()
              }}
            />
            <Field
              name="email"
              validate={(value) => checkEmail(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="email"
                >
                  <div>{t('your_email')}</div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Email Address"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />
            <Field
              name="password"
              validate={(value) => checkPassword(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="password"
                >
                  <div className={css['subscription-label']}>
                    <span className={css['subscription-name']}>{t('password')}</span>
                    <a
                      className={classNames({
                        [css['text-highlight']]: true,
                        [css['font-regular']]: true,
                        [css['text-underline']]: true
                      })}
                      href="/passwords/new"
                      onClick={(e) => {
                        e.preventDefault()
                        onPasswordForgot()
                      }}
                    >
                      {t('forgot_password')}
                    </a>
                  </div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="password"
                      type="password"
                      autoComplete="current-password"
                      placeholder="Password"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['text-center']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitError || submitErrors}
              </p>
            )}

            <div className={css['spacer-bottom']} />

            <button
              className={classNames({
                [css.submit]: true,
                [css['is-loading']]: submitting
              })}
              type="submit"
              disabled={pristine || hasValidationErrors}
            >
              {t('log_in')}
            </button>

            <hr className={css.divider} />

            <h2 className={css.title}>
              <h2 className={classNames({ [css.title]: true, [css['spacer-bottom']]: true })}>
                🏄 SURF
                <span className={css['text-blue']}> PREMIUM</span>
              </h2>
              <span
                className={classNames({
                  [css['font-regular']]: true,
                  [css['font-italic']]: true,
                  [css['text-large']]: true,
                  [css['spacer-bottom']]: true,
                  [css['secondary-highlight']]: true
                })}
              >
                ({t('app')})
              </span>
            </h2>

            <p
              className={classNames({
                [css['text-small']]: true,
                [css['text-highlight']]: true
              })}
            >
              {t('contributing_will_remove_adverts')}
            </p>
            <ul
              className={classNames({
                [css.list]: true,
                [css['spacer-bottom']]: true
              })}
            >
              <li className={css.item}>{t('wavefinder')}</li>
              <li className={css.item}>{t('forecast')}</li>
              <li className={css.item}>{t('alerts')}</li>
              <li className={css.item}>{t('app_features')}</li>
              <li className={css.item}>{t('remove_ads')}</li>
            </ul>

            {['monthly', 'annually'].map((type: ProductDuration) => {
              const price = products[type].prices[currency.acronym]

              return (
                <Field
                  name="subscription"
                  type="radio"
                  value={type}
                  render={({ input }) => (
                    <label
                      className={classNames({
                        [css['is-active']]: input.checked,
                        [css['radio-label']]: true,
                        [css['radio-label-primary']]: true
                      })}
                      htmlFor={`subscription-${type}`}
                    >
                      <div className={css['subscription-label']}>
                        <span className={css['subscription-name']}>{t(`support_${type}`)}</span>
                        <span className={css['subscription-price']}>
                          {currency.prefix}&thinsp;{price.unit_amount / 100}
                        </span>
                      </div>
                      <input
                        {...input}
                        className={css['radio-input']}
                        id={`subscription-${type}`}
                      />
                    </label>
                  )}
                />
              )
            })}

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['text-center']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitError || submitErrors}
              </p>
            )}

            <div className={css['spacer-bottom']} />

            <FormSpy subscription={{ values: true }}>
              {({ values }: { values: FormValues; form: FormApi }) => {
                const price = products[values.subscription].prices[currency.acronym]

                return (
                  <button
                    className={classNames({
                      [css.submit]: true,
                      [css['is-loading']]: submitting
                    })}
                    type="submit"
                    onClick={(event: MouseEvent) => {
                      event.preventDefault()

                      if (!values.email && !values.password) {
                        onUnathenticatedContribute(values.subscription)
                        return
                      }

                      setContribute(true)
                      form.submit()
                    }}
                  >
                    {t('contribute')} {currency.prefix}&thinsp;{price.unit_amount / 100}
                  </button>
                )
              }}
            </FormSpy>
          </form>
        )}
      </Form>
    </div>
  )
}
