import { useStore } from '@nanostores/preact'
import { FormState, FORM_ERROR } from 'final-form'
import { useMemo, useRef, useEffect, useState } from 'preact/hooks'
import { Field, Form, FormRenderProps, FormSpy } from 'react-final-form'

import classNames from 'utils/preact/class-names'

import { send } from './services/backend'
import { checkEmail, checkPassword, checkPasswordConfirm } from './services/validation'
import { FocusFirstError } from './focus-first-error'
import { useTranslation } from './translation'
import { profile } from './store'
import { IsoCountry } from './support'

import css from './style.module.scss'
import eye from './static/eye.svg'
import eyeSlash from './static/eye-slash.svg'

interface FormValues {
  email: string
  password: string
  passwordConfirm: string
  isoCountry: string
}

declare global {
  interface Window {
    sendSignUpEventToHotjar(): any
  }
}

export function IncentiveAuthorization({
  token,
  isoCountries,
  onAuthenticate,
  onAuthorization
}: {
  token: string
  isoCountries: IsoCountry[]
  onAuthenticate: () => void
  onAuthorization: () => void
}) {
  const t = useTranslation()
  const user = useStore(profile)
  const [isInitialized, setIsInitialized] = useState(false)
  const [passwordHidden, setPasswordHidden] = useState(true)

  const formRef = useRef<HTMLFormElement>(null)
  const decorator = useMemo(() => FocusFirstError(formRef), [])

  const mediaQueryList = window.matchMedia('(max-width: 441px)')
  const [isSmall, setIsSmall] = useState(mediaQueryList.matches)

  useEffect(() => {
    const listener = (event) => setIsSmall(event.matches)
    mediaQueryList.addEventListener('change', listener)
    return () => mediaQueryList.removeEventListener('change', listener)
  }, [mediaQueryList])

  const wrapHighlight = (v: string) => `<span class=${css['text-highlight']}>${v}</span>`
  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css['title-incentive']]: true,
          [css['spacer-bottom']]: true
        })}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('join_for_free_to', wrapHighlight, wrapHighlight)
        }}
      />

      <Form
        initialValues={{
          isoCountry: profile.get().countryIso
        }}
        decorators={[decorator]}
        onSubmit={(values: FormValues) => {
          let url: string
          let method: string

          if (user.newAccountCreated) {
            url = '/user'
            method = 'put'
          } else {
            url = '/users/create_with_confirmation'
            method = 'post'
          }

          return send<FormValues & { source: string }>({
            url,
            token,
            method,
            body: JSON.stringify({ user: { ...values, source: 'incentive' } })
          }).then((result) => {
            if (result.success) return undefined
            if (result.error) return { [FORM_ERROR]: result.error }
            return result.errors
          })
        }}
      >
        {({
          handleSubmit,
          hasSubmitErrors,
          submitError,
          pristine,
          hasValidationErrors,
          submitting,
          values,
          form
        }: FormRenderProps & FormState<FormValues>) => (
          <form
            action="/users/create_with_confirmation"
            method="post"
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <FormSpy
              subscription={{ pristine: true }}
              onChange={() => {
                if (!isInitialized) {
                  form.batch(() => {
                    form.change('email', user.email)
                    form.change('password', user.password)
                    form.change('passwordConfirm', user.passwordConfirm)
                    form.change('isoCountry', user.countryIso)
                  })
                  setIsInitialized(true)
                }
              }}
            />
            <FormSpy
              subscription={{ submitSucceeded: true }}
              onChange={({ submitSucceeded }: { submitSucceeded: boolean }) => {
                if (submitSucceeded) {
                  profile.setKey('newAccountCreated', true)
                  profile.setKey('reloadRequired', true)
                  profile.setKey('authorized', true)

                  profile.setKey('email', values.email)
                  profile.setKey('password', values.password)
                  profile.setKey('passwordConfirm', values.passwordConfirm)
                  profile.setKey('countryIso', values.isoCountry)
                  onAuthorization()
                }
              }}
            />

            <Field
              name="email"
              validate={(value: string) => checkEmail(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="email"
                >
                  <div>{t('your_email')}</div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="email"
                      type="email"
                      autoComplete="email"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                      onFocus={(event) => {
                        if (typeof window.sendSignUpEventToHotjar === 'function')
                          window.sendSignUpEventToHotjar()
                        try {
                          ;(event.target as HTMLInputElement).scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'center'
                          })
                        } catch (error) {
                          // eslint-disable-next-line no-console
                          console.error(error)
                        }
                      }}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />

            <Field
              name="isoCountry"
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="isoCountry"
                >
                  <div>{t('country')}</div>
                  <div className={css['input-wrapper']}>
                    <select
                      {...input}
                      id="isoCountry"
                      className={classNames({
                        [css.input]: true,
                        [css['is-dropdown']]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    >
                      {isoCountries.map((isoCountry) => (
                        <option value={isoCountry.iso}>{isoCountry.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />

            <div className={css['spacer-bottom']} style={{ display: 'flex' }}>
              <Field
                name="password"
                validate={(value: string) => checkPassword(t, value)}
                render={({ input, meta }) => (
                  <label
                    className={classNames({
                      [css.label]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                    htmlFor="password"
                  >
                    <div className={css['password-field-label']}>
                      <span>{t('password')}</span>
                      <button
                        type="button"
                        className={css['password-field-toggle']}
                        onClick={() => setPasswordHidden((value) => !value)}
                      >
                        <img
                          src={passwordHidden ? eye : eyeSlash}
                          alt={passwordHidden ? 'Show password' : 'Hide password'}
                        />
                      </button>
                    </div>
                    <div
                      className={classNames({
                        [css['input-wrapper']]: true,
                        [css['password-field']]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    >
                      <input
                        {...input}
                        id="password"
                        type={passwordHidden ? 'password' : 'text'}
                        autoComplete="new-password"
                        className={classNames({
                          [css.input]: true,
                          [css['is-valid']]: meta.touched && meta.valid,
                          [css['is-invalid']]: meta.touched && meta.invalid
                        })}
                      />
                    </div>
                    <div className={css.error}>
                      {(meta.touched && meta.error) || meta.submitError}
                    </div>
                  </label>
                )}
              />
              <div style={{ width: '16px' }} />
              <Field
                name="passwordConfirm"
                validate={(value: string, { password }: FormValues) =>
                  checkPasswordConfirm(t, value, password)
                }
                render={({ input, meta }) => (
                  <label
                    className={classNames({
                      [css.label]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                    htmlFor="passwordConfirm"
                  >
                    <div>{isSmall ? t('repeat') : t('repeat_password')}</div>
                    <div
                      className={classNames({
                        [css['input-wrapper']]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    >
                      <input
                        {...input}
                        id="passwordConfirm"
                        type={passwordHidden ? 'password' : 'text'}
                        autoComplete="new-password"
                        className={classNames({
                          [css.input]: true,
                          [css['is-valid']]: meta.touched && meta.valid,
                          [css['is-invalid']]: meta.touched && meta.invalid
                        })}
                      />
                    </div>
                    <div className={css.error}>
                      {(meta.touched && meta.error) || meta.submitError}
                    </div>
                  </label>
                )}
              />
            </div>

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['text-center']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitError}
              </p>
            )}
            <button
              className={classNames({
                [css.submit]: true,
                [css['is-loading']]: submitting
              })}
              type="submit"
              disabled={pristine || hasValidationErrors}
            >
              {t('next')}
            </button>

            <p
              className={classNames({
                [css['text-tiny']]: true,
                [css['font-regular']]: true,
                [css['text-center']]: true
              })}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t(
                  'agree_to_terms',
                  (v) => `<a href="/pages/privacy_policy" target="_blank">${v}</a>`
                )
              }}
            />
            <p
              className={classNames({
                [css['text-center']]: true,
                [css['text-small']]: true
              })}
            >
              {t('already_have_account')}{' '}
              <a
                className={classNames({
                  [css['text-underline']]: true,
                  [css['spacer-bottom']]: true
                })}
                href="/login"
                onClick={(event: MouseEvent) => {
                  event.preventDefault()
                  onAuthenticate()
                }}
              >
                {t('log_in')}
              </a>
            </p>
          </form>
        )}
      </Form>
    </div>
  )
}
