export default function applyRequestIdleCallback() {
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function requestIdleCallback(handler) {
      const startTime = Date.now();

      return setTimeout(() => {
        handler({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50.0 - (Date.now() - startTime));
          },
        });
      }, 1);
    };

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function cancelIdleCallback(id) {
      clearTimeout(id);
    };
}
