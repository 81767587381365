// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md

export default function applyAppend() {
  const arr = [
    Element.prototype,
    Document.prototype,
    DocumentFragment.prototype,
  ];

  arr.forEach(item => {
    if (Object.hasOwnProperty.call(item, 'append')) return;

    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append(...args) {
        const docFrag = document.createDocumentFragment();

        args.forEach(argItem => {
          const isNode = argItem instanceof Node;
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          );
        });

        this.appendChild(docFrag);
      },
    });
  });
}
