import polyfill from './polyfill';
import readMore from './js/read-more';
import countdown from './js/countdown';
import carousel from './js/carousel';
import './styles/application.scss';

export default function init() {
  polyfill();
  readMore();
  countdown();
  carousel();
}
