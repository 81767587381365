import './statistics.scss';

export const BLOCK_ID = '#surf-wind-statistics';

export default function statistics() {
  const block = document.body.querySelector(BLOCK_ID);
  if (!block) return;

  import(/* webpackChunkName: "surf-wind-statistics" */ './toggle-chart').then(
    ({ default: toggleChart }) => {
      toggleChart(block);
    }
  );
}
