import { BaseComponent } from 'utils/base-component'
import './country-flag.scss'

const images = require.context('./static', true, /\.svg$/)

enum Flag {
  width = 0,
  height
}
type Config = {
  iso?: string
  size?: Array<number>
}

export class CountryFlag extends BaseComponent<Config> {
  static cssClass = 'country-flag'

  private flag!: HTMLImageElement

  init() {
    this.flag = document.createElement('img')
    this.flag.classList.add(this.elementClassName('flag'))
    if (this.config.iso) this.updateIso(this.config.iso)
    this.updateSize(this.config.size || [24, 18])
    this.root.appendChild(this.flag)
  }

  updateIso(iso: string) {
    this.flag.src = images(`${iso.toLowerCase()}.svg`)
  }

  updateSize(size: Array<number>) {
    this.flag.width = size[Flag.width]
    this.flag.height = size[Flag.height]
  }
}
