import throttle from 'utils/throttle';

export default class TideClock {
  constructor(el) {
    this.el = el;
  }

  init() {
    this.onScroll = throttle(() => {
      this.rotateArrowIfNeeded();
    }, 250);
    window.addEventListener('scroll', this.onScroll);
  }

  rotateArrowIfNeeded() {
    const position = this.el.getBoundingClientRect();

    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      const needle = this.el.querySelector('.guide-page__needle');
      needle.style.transform = `rotate(${needle.dataset.deg}deg)`;
      window.removeEventListener('scroll', this.onScroll);
    }
  }
}
