import './index.scss'
import { BaseComponent, BElement } from 'utils/base-component'
import './static/icon-close.svg'
import './static/compose.svg'

export class Tweets extends BaseComponent {
  static cssClass = 'tweets'

  private forwardButton!: BElement | null
  private backwardButton!: BElement | null
  private tweetsList!: BElement
  private tweets!: BElement[]
  private modal!: BElement
  private modalButton!: BElement
  private closeModalButton!: BElement

  init() {
    this.forwardButton = this.element('next-tweet')
    this.backwardButton = this.element('previous-tweet')
    this.tweetsList = this.element('tweets')
    this.tweets = this.elements('tweet')

    this.modal = this.element('modal-container')
    this.modalButton = this.element('compose')
    this.closeModalButton = this.element('modal-close')

    let currentIndex: number = 0

    if (!this.modal || !this.modalButton) {
      return
    }

    this.modalButton.element.addEventListener('click', () => {
      this.modal.toggleModifier('hidden')
    })

    this.closeModalButton.element.addEventListener('click', () => {
      this.modal.toggleModifier('hidden', true)
    })

    if (!this.forwardButton || !this.backwardButton) {
      return
    }

    this.backwardButton.element.addEventListener('click', () => {
      currentIndex = (currentIndex - 1 + this.tweets.length) % this.tweets.length
      this.scrollToTweet(currentIndex)
    })

    this.forwardButton.element.addEventListener('click', () => {
      currentIndex = (currentIndex + 1) % this.tweets.length
      this.scrollToTweet(currentIndex)
    })
  }

  private scrollToTweet(index: number) {
    if (this.tweets[index]) {
      const tweetWidth = this.tweets[index].element.getBoundingClientRect().width
      const newScrollPosition = tweetWidth * index
      this.tweetsList.element.scroll({
        left: newScrollPosition,
        behavior: 'smooth'
      })
    }
  }
}
