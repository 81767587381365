export enum Pages {
  LoaderPage,
  LoadingErrorPage,
  AuthorizationPage,
  AuthenticationPage,
  AuthenticationSubscribePage,
  SubscriptionsPage,
  AuthenticatedSubscribePage,
  AlreadySubscribedPage,
  PaymentPage,
  PaymentSuccesfulPage,
  WelcomePaidPage,
  WelcomeFreePage,
  EmailVerifyPage,
  EmailUpdatePage,
  PasswordResetPage,
  PasswordUpdatePage,
  EmailSentPage
}
