import { useEffect, useState } from 'preact/hooks'
import { JSXInternal } from 'preact/src/jsx'

import { AlreadySubscribed } from './already-subscribed'
import { AuthenticatedSubscribe } from './authenticated-subscribe'
import { Authentication } from './authentication'
import { AuthenticationSubscribe } from './authentication-subscribe'
import { Authorization } from './authorization'
import { EmailSent } from './email-sent'
import { EmailUpdate } from './email-update'
import { EmailVerify } from './email-verify'
import { Loader } from './loader'
import { LoadingError } from './loading-error'
import { Pages } from './pages'
import { PasswordReset } from './password-reset'
import { PasswordUpdate } from './password-update'
import { Payment } from './payment'
import { PaymentSuccesful } from './payment-succesful'
import { getSupport } from './services/backend'
import { profile } from './store'
import css from './style.module.scss'
import { Support } from './support'
import { TranslationProvider } from './translation'
import { WelcomeFree } from './welcome-free'
import { WelcomePaid } from './welcome-paid'
import { WrapperHeader } from './wrapper-header'

const meta = document.querySelector('meta[name=csrf-token]') as HTMLMetaElement
const token = meta ? meta.content : ''

export function Modal({
  action,
  source,
  freeAvailable,
  onClose,
  onReload
}: {
  action: Pages
  source: string
  freeAvailable: boolean
  onClose: () => void
  onReload: (action: Pages) => void
}) {
  const [errorMessage, setErrorMessage] = useState('')
  const [support, setSupport] = useState<Support | null>(null)

  const [history, setHistory] = useState<Pages[]>([])
  const [page, setPage] = useState(Pages.LoaderPage)

  const popHistory = () => {
    setHistory(history.slice(0, -1))
    setPage(history[history.length - 1])
  }

  const updatePage = (newPage: Pages) => {
    setHistory(history.concat(page))
    setPage(newPage)
  }

  useEffect(() => {
    setErrorMessage('')
    ;(async () => {
      let data: Support
      try {
        data = await getSupport()
      } catch (error) {
        let msg = 'fetching support, but the error is unknown'
        if (error instanceof Error) msg = error.message

        setPage(Pages.LoadingErrorPage)
        setErrorMessage(msg)

        return
      }

      setSupport(data)
      profile.setKey('source', source)
      profile.setKey('premium', data.user.premium)
      profile.setKey('authorized', data.user.signedIn)
      profile.setKey('countryIso', data.default_iso_country)
      profile.setKey('emailVerified', data.user.emailVerified)

      if (action === Pages.PasswordUpdatePage) {
        setPage(action)
        return
      }

      if (data.user.signedIn) {
        if (data.user.emailVerified) {
          setPage(Pages.AuthenticatedSubscribePage)
        } else {
          setPage(Pages.EmailVerifyPage)
        }
      } else {
        setPage(action)
      }
    })()
  }, [action, source])

  const reloadPage = () => {
    if (action === Pages.PasswordUpdatePage) {
      window.location.assign('/')
    } else {
      window.location.reload()
    }
  }
  const closePage = () => {
    if (profile.get().reloadRequired) {
      reloadPage()
    } else {
      onClose()
    }
  }

  let el: JSXInternal.Element
  switch (page) {
    case Pages.LoaderPage:
      el = (
        <WrapperHeader onClose={closePage}>
          <Loader />
        </WrapperHeader>
      )
      break
    case Pages.LoadingErrorPage:
      el = (
        <WrapperHeader onClose={closePage}>
          <LoadingError message={errorMessage} onReload={reloadPage} />
        </WrapperHeader>
      )
      break
    case Pages.AuthenticatedSubscribePage:
      el = (
        <WrapperHeader onClose={closePage} tooltip="1/2">
          <AuthenticatedSubscribe
            token={token}
            products={support.products}
            currencies={support.currencies}
            isoCountries={support.iso_countries}
            freeAvailable={freeAvailable}
            onFree={() => closePage()}
            onPaid={() => updatePage(Pages.PaymentPage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.AlreadySubscribedPage:
      el = (
        <WrapperHeader onClose={closePage}>
          <AlreadySubscribed currencies={support.currencies} onClose={() => onReload(action)} />
        </WrapperHeader>
      )
      break
    case Pages.AuthorizationPage:
      el = (
        <WrapperHeader onClose={closePage} tooltip="1/3">
          <Authorization
            token={token}
            products={support.products}
            currencies={support.currencies}
            isoCountries={support.iso_countries}
            freeAvailable={freeAvailable}
            onAuthenticate={() => updatePage(Pages.AuthenticationSubscribePage)}
            onPaid={() => updatePage(Pages.PaymentPage)}
            onFree={() => updatePage(Pages.EmailVerifyPage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.AuthenticationPage:
      el = (
        <WrapperHeader onClose={closePage} onBack={popHistory}>
          <Authentication
            token={token}
            onAuthenticate={() => updatePage(Pages.PaymentPage)}
            onPasswordForgot={() => updatePage(Pages.PasswordResetPage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.AuthenticationSubscribePage:
      el = (
        <WrapperHeader
          onClose={closePage}
          onBack={history.length ? popHistory : undefined}
          tooltip="1/2"
        >
          <AuthenticationSubscribe
            token={token}
            products={support.products}
            currencies={support.currencies}
            isoCountries={support.iso_countries}
            onPasswordForgot={() => updatePage(Pages.PasswordResetPage)}
            onAuthenticate={reloadPage}
            onAuthenticatedContribute={(subscription) => {
              if (profile.get().premium) {
                updatePage(Pages.AlreadySubscribedPage)
                return
              }

              profile.setKey('authorized', true)
              profile.setKey('subscription', subscription)
              updatePage(Pages.PaymentPage)
            }}
            onUnathenticatedContribute={(subscription) => {
              profile.setKey('subscription', subscription)
              updatePage(Pages.AuthenticationPage)
            }}
          />
        </WrapperHeader>
      )
      break
    case Pages.PaymentPage:
      el = (
        <WrapperHeader
          onClose={closePage}
          onBack={popHistory}
          tooltip={history[history.length - 1] === Pages.AuthorizationPage ? '2/3' : '2/2'}
        >
          <Payment
            token={token}
            products={support.products}
            currencies={support.currencies}
            isoCountries={support.iso_countries}
            publishableKey={support.stripe_publishable_key}
            onPay={() => updatePage(Pages.PaymentSuccesfulPage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.PaymentSuccesfulPage:
      el = (
        <WrapperHeader>
          <PaymentSuccesful
            onNext={() => {
              if (profile.get().emailVerified) {
                updatePage(Pages.WelcomePaidPage)
              } else {
                updatePage(Pages.EmailVerifyPage)
              }
            }}
          />
        </WrapperHeader>
      )
      break
    case Pages.WelcomePaidPage:
      el = (
        <WrapperHeader onClose={closePage}>
          <WelcomePaid onClose={closePage} />
        </WrapperHeader>
      )
      break
    case Pages.WelcomeFreePage:
      el = (
        <WrapperHeader onClose={closePage}>
          <WelcomeFree onClose={closePage} />
        </WrapperHeader>
      )
      break
    case Pages.EmailVerifyPage:
      el = (
        <WrapperHeader
          onClose={closePage}
          tooltip={history[history.length - 1] === Pages.PaymentPage ? '3/3' : '2/2'}
        >
          <EmailVerify
            token={token}
            onVerify={() => {
              if (profile.get().premium) {
                updatePage(Pages.WelcomePaidPage)
                return
              }

              updatePage(Pages.WelcomeFreePage)
            }}
            onEmailUpdate={() => updatePage(Pages.EmailUpdatePage)}
          />
        </WrapperHeader>
      )
      break
    case Pages.EmailUpdatePage:
      el = (
        <WrapperHeader onClose={closePage} onBack={popHistory}>
          <EmailUpdate token={token} onEmailUpdated={popHistory} incentiveContainer={false} />
        </WrapperHeader>
      )
      break
    case Pages.PasswordResetPage:
      el = (
        <WrapperHeader onBack={popHistory}>
          <PasswordReset token={token} onPasswordReset={() => setPage(Pages.EmailSentPage)} />
        </WrapperHeader>
      )
      break
    case Pages.EmailSentPage:
      el = (
        <WrapperHeader onClose={closePage}>
          <EmailSent modal onClose={closePage} />
        </WrapperHeader>
      )
      break
    case Pages.PasswordUpdatePage:
      el = (
        <WrapperHeader>
          <PasswordUpdate
            token={token}
            onUpdate={() => setPage(Pages.AuthenticationSubscribePage)}
          />
        </WrapperHeader>
      )
      break
    default:
      throw new Error(`Unexpected error. Specified page (${page}) is not supported.`)
  }

  return (
    <TranslationProvider definitions={support?.messages}>
      <div
        onClick={(event) => {
          if ((event.target as HTMLElement) === (event.currentTarget as HTMLElement)) {
            closePage()
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            closePage()
          }
        }}
        role="button"
        tabIndex={0}
        className={css.backdrop}
      >
        {el}
      </div>
    </TranslationProvider>
  )
}
