import './index.scss'
import './dynamic.scss'

import './static/icon--s0.svg'
import './static/icon--s1.svg'
import './static/icon--s2.svg'
import './static/icon--s3.svg'
import './static/icon--s4.svg'
import './static/icon--s5.svg'
import './static/icon--alert.svg'
import './static/get_alerts--temp.gif'
import './background/background--1.jpg'
import './background/background--2.jpg'
import './background/background--3.jpg'
import './background/background--4.jpg'
import './background/background--5.jpg'
import './background/background--6.jpg'
import './background/background--7.jpg'
import './background/background--8.jpg'
import './background/background--9.jpg'
import './background/background--10.jpg'

import './static/surf-alert.png'
import './static/surf-alert@2.png'
import './static/surf-alert.webp'

import { BaseComponent } from 'utils/base-component'
import units from 'utils/units'

export class BreakHeader extends BaseComponent {
  static cssClass = 'break-header-dynamic'

  statisticEl!: HTMLElement | null
  tempText!: HTMLElement | null

  init() {
    this.statisticEl = this.element('statistic').element
    this.tempText = this.statisticEl.querySelector('strong')
    if (!this.tempText) return

    units.onChange(() => this.updateTempStatistics())
    this.updateTempStatistics()
  }

  updateTempStatistics() {
    const matches = this.tempText.innerText.match(/\d+°(F|C) (.*)/)
    if (!matches) return

    const currentTempUnits = units.current.temperature
    const prevUnits = matches[1] === 'C' ? 'Metric' : 'Imperial'
    const otherText = matches[2]

    if (prevUnits === currentTempUnits) return

    const currentTemp = parseFloat(this.statisticEl.dataset.currentTemp)
    const meanTemp = parseFloat(this.statisticEl.dataset.meanTemp)
    const difference = Math.abs(currentTemp - meanTemp)

    const newValue =
      currentTempUnits === 'Metric' ? difference.toFixed(1) : (difference * 1.8).toFixed(1)
    this.tempText.innerText = `${newValue}${units.formatTempUnits()} ${otherText}`
  }
}
