function classNames(classes) {
  return Object.entries(classes)
    .reduce((acc, [className, expression]) => {
      if (expression) acc.push(className);
      return acc;
    }, [])
    .join(' ');
}

export default classNames;
