import { useStore } from '@nanostores/preact'
import classNames from 'utils/preact/class-names'

import { send } from './services/backend'
import { useTranslation } from './translation'
import { profile, SubscriptionValue } from './store'
import { Currency, IsoCountry, ProductDuration, Products } from './support'

import css from './style.module.scss'
import speedIcon from './static/speed.svg'
import removeIcon from './static/remove.svg'
import extendIcon from './static/extend.svg'

function ViewA({
  currency,
  products,
  onSubmit
}: {
  currency: Currency
  products: Products
  onSubmit: (event: Event) => void
}) {
  const t = useTranslation()
  const user = useStore(profile)

  return (
    <div className={css.content}>
      <div
        className={classNames({
          [css['font-heavy']]: true,
          [css['title-incentive']]: true,
          [css['text-center']]: true,
          [css['spacer-bottom']]: true
        })}
      >
        <div className={css['font-black']}>
          <h2 className={classNames({ [css.title]: true, [css['spacer-bottom']]: true })}>
            🏄 SURF
            <span className={css['text-blue']}> PREMIUM</span>
          </h2>{' '}
          <span
            className={classNames({
              [css['font-regular']]: true,
              [css['font-italic']]: true,
              [css['text-large']]: true,
              [css['spacer-bottom']]: true,
              [css['secondary-highlight']]: true
            })}
          >
            ({t('app')})
          </span>
        </div>
      </div>

      <p
        className={classNames({
          [css['text-tiny']]: true,
          [css['text-highlight']]: true
        })}
      >
        {t('contributing_will_remove_adverts_which_means')}
      </p>

      <form action="/profile" method="post" onSubmit={onSubmit}>
        {['monthly', 'annually'].map((type: ProductDuration, i, list) => {
          const price = products[type].prices[currency.acronym]
          return (
            <label
              className={classNames({
                [css.sm]: true,
                [css['radio-label']]: true,
                [css['radio-label-primary']]: true,
                [css['mb-1']]: list.length - 1 === i,
                [css['is-active']]: user.subscription === type
              })}
              htmlFor={`subscription-${type}`}
            >
              <div className={css['subscription-label']}>
                <span className={css['subscription-name']}>{t(`support_${type}`)}</span>
                <span className={css['subscription-price']}>
                  {currency.prefix}&thinsp;{price.unit_amount / 100}
                </span>
              </div>
              <input
                type="radio"
                name="subscription"
                value={type}
                className={css['radio-input']}
                id={`subscription-${type}`}
                onChange={() => profile.setKey('subscription', type)}
              />
            </label>
          )
        })}
        <p
          className={classNames({
            [css['mb-1']]: true,
            [css['text-small']]: true,
            [css['font-italic']]: true,
            [css['font-regular']]: true,
            [css['text-center']]: true
          })}
        >
          {t('cant_support_now')}
        </p>
        <label
          className={classNames({
            [css['is-active']]: user.subscription === 'free',
            [css['radio-label']]: true,
            [css['radio-label-secondary']]: true,
            [css.sm]: true
          })}
          htmlFor="subscription-free"
        >
          <div>{t('continue_free')}</div>
          <input
            type="radio"
            name="subscription"
            value="free"
            className={css['radio-input']}
            id="subscription-free"
            onChange={() => profile.setKey('subscription', 'free')}
          />
        </label>
        <button
          className={classNames({
            [css.sm]: true,
            [css.submit]: true
          })}
          type="submit"
        >
          {user.subscription === 'free'
            ? t('continue')
            : `${t('contribute')} ${currency.prefix}${
                products[user.subscription as ProductDuration].prices[currency.acronym]
                  .unit_amount / 100
              }`}
        </button>
      </form>
    </div>
  )
}

function ViewB({
  currency,
  products,
  onSubmit
}: {
  currency: Currency
  products: Products
  onSubmit: (v: SubscriptionValue) => void
}) {
  const t = useTranslation()

  return (
    <div className={css.content}>
      <div className={css['access-block']}>
        <div
          className={classNames({
            [css['font-heavy']]: true,
            [css['title-incentive']]: true,
            [css['text-center']]: true,
            [css['spacer-bottom']]: true,
            [css['text-blue']]: true
          })}
        >
          {t('get_full_access')}
        </div>

        <ul className={css['benefits-list']}>
          <li className={css['font-medium']}>
            <img src={removeIcon} alt="Remove Icon" className={css.icon} />
            {t('remove_ads')}
          </li>
          <li className={css['font-medium']}>
            <img src={speedIcon} alt="speed Icon" className={css.icon} />
            {t('faster_site')}
          </li>
          <li className={css['font-medium']}>
            <img src={extendIcon} alt="Extended Icon" className={css.icon} />
            {t('extended_forecasts')}
          </li>
        </ul>

        <div className={css['spacer-bottom']} />

        {['monthly', 'annually'].map((type: ProductDuration) => {
          const price = products[type].prices[currency.acronym]
          return (
            <button
              type="button"
              className={classNames({
                [css['subscription-button']]: true,
                [css['is-primary']]: true
              })}
              onClick={() => onSubmit(type)}
            >
              <span className={css['subscription-name']}>{t(`support_${type}`)}</span>
              <span className={css['subscription-price']}>
                {currency.prefix}&thinsp;{price.unit_amount / 100}
              </span>
            </button>
          )
        })}
      </div>
      <p
        className={classNames({
          [css['mb-1']]: true,
          [css['text-small']]: true,
          [css['font-italic']]: true,
          [css['font-regular']]: true,
          [css['text-center']]: true
        })}
      >
        {t('cant_support_now')}
      </p>
      <button
        type="button"
        className={classNames({
          [css['subscription-link-like']]: true
        })}
        onClick={() => onSubmit('free')}
      >
        <div className={css['font-regular']}>{t('continue_free')}</div>
      </button>
    </div>
  )
}

enum Experiments {
  A,
  B
}

export function IncentiveSubscriptions({
  token,
  products,
  currencies,
  isoCountries,
  experiment,
  onFree,
  onPaid
}: {
  token: string
  products: Products
  currencies: Currency[]
  isoCountries: IsoCountry[]
  experiment: number
  onFree: () => void
  onPaid: () => void
}) {
  const user = useStore(profile)
  const country = isoCountries.find((c) => c.iso === user.countryIso)
  const currency = currencies.find((c) => c.acronym === country.currency)

  const onSelect = async () => {
    const { subscription } = profile.get()
    const result = await send<{ initial_subscription: SubscriptionValue }>({
      token,
      method: 'put',
      url: '/profile',
      body: JSON.stringify({ user: { initial_subscription: subscription }, source: user.source })
    })

    if (result.error || result.errors) {
      let error: string
      if (result.error) error = result.error
      else if (result.errors) [error] = Object.values(result.errors)
      // eslint-disable-next-line no-console
      console.error('Failed while sending initial subscription data.', error)
    }

    if (subscription === 'free') onFree()
    else onPaid()
  }

  switch (experiment) {
    case Experiments.A:
      return (
        <ViewA
          currency={currency}
          products={products}
          onSubmit={(event: Event) => {
            event.preventDefault()
            onSelect()
          }}
        />
      )
    case Experiments.B:
      return (
        <ViewB
          currency={currency}
          products={products}
          onSubmit={(v: SubscriptionValue) => {
            profile.setKey('subscription', v)
            onSelect()
          }}
        />
      )
    default:
      // eslint-disable-next-line no-console
      console.error(
        'Failed while trying to determine which page to show. Unknown value:',
        experiment
      )
      break
  }
}
