import classNames from 'utils/preact/class-names'
import { useTranslation } from './translation'
import css from './style.module.scss'

export function EmailSent({
  incentiveContainer,
  modal,
  onClose
}: {
  incentiveContainer?: boolean
  modal: boolean
  onClose?: () => void
}) {
  const t = useTranslation()

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: !incentiveContainer,
          [css['title-incentive']]: incentiveContainer,
          [css['spacer-bottom']]: true
        })}
      >
        {t('email_sent')}
      </h2>
      <p
        className={classNames({
          [css['text-small']]: true,
          [css['font-regular']]: true
        })}
      >
        {' '}
        {t('you_will_receive')}
      </p>
      {modal && (
        <button className={css.submit} type="button" onClick={onClose}>
          {t('close')}
        </button>
      )}
    </div>
  )
}
