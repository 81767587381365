import L from 'leaflet';

import './resort-webcams.scss';
import './static/snoweye-logo.gif';
import './static/spinner.gif';

import './static/icon--archive.svg';
import './static/icon--panorama.svg';
import './static/icon--player.svg';

function preloadSnoweyeWebcam(webcam) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = webcam.image;
    image.addEventListener('load', () => {
      webcam.imageObj = image; // eslint-disable-line no-param-reassign
      if (image.width + image.height >= 640) {
        resolve(webcam);
      } else {
        reject();
      }
    });
    image.addEventListener('error', () => reject());
  });
}

class ResortWebcams {
  constructor(el, className, webcams, maxPanoHeight = 418) {
    this.el = el;
    this.className = className;
    this.webcams = webcams;
    this.webcamIndex = 0;
    this.photoIndex = 0;
    this.maxPanoHeight = maxPanoHeight;
  }

  preloadSnoweyeWebcams() {
    return new Promise(resolve => {
      const webcamsToPreload = [];
      this.webcams = this.webcams.filter(webcam => {
        if (!webcam.snoweye || webcam.special) {
          return true;
        }

        webcamsToPreload.push(webcam);
        return false;
      });

      const promises = webcamsToPreload.map(webcam =>
        preloadSnoweyeWebcam(webcam)
      );

      Promise.allSettled(promises).then(results => {
        results.forEach(result => {
          if (result.status === 'fulfilled') {
            this.webcams.push(result.value);
          }
        });

        resolve();
      });
    });
  }

  init() {
    this.prepareContainer();
    this.preloadSnoweyeWebcams().then(() => {
      this.renderWebcamList();
      if (!this.webcams[0].default) {
        this.selectWebcam(0);
      }
    });
  }

  renderThumb(snapshot, index) {
    const item = document.createElement('li');
    item.classList.add(this.bem('webcam-list-item'));
    item.classList.add(this.bem('webcam-list-item', 'loading'));

    const archived = document.createElement('div');
    archived.classList.add(this.bem('archived-time'));
    archived.innerText = snapshot.issued;

    item.appendChild(archived);

    const img = new Image();
    img.src = snapshot.thumbnail;
    img.classList.add(this.bem('webcam-thumbnail'));

    img.addEventListener('load', () => {
      item.classList.remove(this.bem('webcam-list-item', 'loading'));
    });

    img.addEventListener('error', () => {
      item.classList.remove(this.bem('webcam-list-item', 'loading'));
      item.classList.add(this.bem('webcam-list-item', 'error'));
    });

    item.appendChild(img);

    item.addEventListener('click', e => {
      e.preventDefault();

      this.selectPhoto(index);
      this.scrollIntoViewIfNeeded();
    });

    this.thumbs.appendChild(item);
  }

  renderThumbs() {
    const archive = this.el.querySelector(this.bemWithDot('webcam-archive'));
    const { snapshots } = this.webcams[this.webcamIndex];

    this.thumbs = archive.querySelector(this.bemWithDot('webcam-list'));
    this.thumbs.innerHTML = '';

    if (!snapshots || snapshots.length === 0) {
      archive.classList.add(this.bem('webcam-archive', 'hidden'));
      return;
    }
    archive.classList.remove(this.bem('webcam-archive', 'hidden'));

    snapshots.forEach((snapshot, index) => {
      this.renderThumb(snapshot, index);
    });
  }

  selectPhoto(index) {
    this.photoIndex = index;

    this.image.classList.remove(this.bem('webcam', 'hidden'));
    this.container.classList.add(this.bem('webcam-container', 'loading'));
    const snapshot = this.webcams[this.webcamIndex].snapshots[this.photoIndex];
    this.image.src = snapshot.image;
    this.image.alt = index === 0 ? this.image.dataset.alt : '';

    const issued = this.container.querySelector(this.bemWithDot('issued'));
    if (snapshot.issued) {
      issued.classList.remove(this.bem('issued', 'hidden'));
      const value = issued.querySelector(this.bemWithDot('issued-value'));
      value.innerText = snapshot.issued;
    } else {
      issued.classList.add(this.bem('issued', 'hidden'));
    }

    const active = this.thumbs.querySelector(
      this.bemWithDot('webcam-list-item', 'active')
    );
    if (active) {
      active.classList.remove(this.bem('webcam-list-item', 'active'));
    }

    const selector = `${this.bemWithDot('webcam-list-item')}:nth-child(${
      this.photoIndex + 1
    })`;
    const selectedThumbnail = this.thumbs.querySelector(selector);
    selectedThumbnail.classList.add(this.bem('webcam-list-item', 'active'));
  }

  prepareContainer() {
    this.container = this.el.querySelector(this.bemWithDot('webcam-container'));
    this.image = this.container.querySelector(this.bemWithDot('webcam'));

    this.image.addEventListener('load', () => {
      this.container.classList.remove(this.bem('webcam-container', 'loading'));
    });

    this.image.addEventListener('error', () => {
      this.container.classList.remove(this.bem('webcam-container', 'loading'));
      this.container.classList.add(this.bem('webcam-container', 'error'));
    });
  }

  updateCredit() {
    const webcam = this.webcams[this.webcamIndex];
    const creditContainer = this.el.querySelector(this.bemWithDot('credit'));

    if (webcam.credit) {
      const { label, url } = webcam.credit;
      creditContainer.classList.remove(this.bem('credit', 'hidden'));

      const creditLink = creditContainer.querySelector(
        this.bemWithDot('credit-link')
      );
      creditLink.innerText = label;
      creditLink.href = url;
    } else {
      creditContainer.classList.add(this.bem('credit', 'hidden'));
    }

    this.toggleSnoweyeCredit();
  }

  updateTitle() {
    const webcam = this.webcams[this.webcamIndex];
    const titleEl = this.el.querySelector(this.bemWithDot('webcam-title'));

    if (webcam.title) {
      titleEl.classList.remove(this.bem('webcam-title', 'hidden'));
      titleEl.innerText = webcam.title;
    } else {
      titleEl.classList.add(this.bem('webcam-title', 'hidden'));
      titleEl.innerText = '';
    }
  }

  toggleSnoweyeCredit() {
    const el = this.el.querySelector(this.bemWithDot('snoweye-credit'));

    if (!el) return;

    const webcam = this.webcams[this.webcamIndex];

    if (webcam.snoweye) {
      el.classList.remove(this.bem('snoweye-credit', 'hidden'));
    } else {
      el.classList.add(this.bem('snoweye-credit', 'hidden'));
    }
  }

  renderSpecial() {
    this.container.classList.add(this.bem('webcam-container', 'special'));

    const { special } = this.webcams[this.webcamIndex];

    this.specialWebcam = document.createElement('div');
    this.specialWebcam.classList.add(this.bem('special-webcam'));

    if (special.type === 'feratel') {
      this.specialWebcam.classList.add(this.bem('special-webcam', 'feratel'));
    }

    const { aspect_ratio: aspectRatio } = special;
    if (aspectRatio) {
      this.specialWebcam.style.paddingTop = `${aspectRatio * 100}%`;
    }

    const iframe = document.createElement('iframe');
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('webkitallowfullscreen', true);
    iframe.setAttribute('mozallowfullscreen', true);
    iframe.setAttribute('allowfullscreen', true);
    iframe.setAttribute('allow', 'fullscreen');
    iframe.setAttribute('src', special.url);

    this.specialWebcam.appendChild(iframe);
    this.container.appendChild(this.specialWebcam);
  }

  renderWebcamArchive() {
    this.renderThumbs();
    this.selectPhoto(0);
  }

  resetContainer() {
    this.container.classList.remove(this.bem('webcam-container', 'special'));
    this.image.classList.add(this.bem('webcam', 'hidden'));
    this.image.alt = '';

    if (this.specialWebcam) {
      this.container.removeChild(this.specialWebcam);
      this.specialWebcam = null;
    }

    const archive = this.el.querySelector(this.bemWithDot('webcam-archive'));
    archive.classList.add(this.bem('webcam-archive', 'hidden'));

    const issued = this.container.querySelector(this.bemWithDot('issued'));
    issued.classList.add(this.bem('issued', 'hidden'));
  }

  renderRegularPhoto() {
    const webcam = this.webcams[this.webcamIndex];

    this.image.classList.remove(this.bem('webcam', 'hidden'));

    this.container.classList.add(this.bem('webcam-container', 'loading'));

    this.image.src = webcam.image;
  }

  initLeaflet(img) {
    const map = L.map(this.specialWebcam, {
      minZoom: 1,
      maxZoom: 4,
      center: [0, 0],
      zoom: 1,
      crs: L.CRS.Simple,
      maxBoundsViscosity: 1.0,
    });

    const height = Math.round(this.specialWebcam.offsetHeight);
    const width = Math.round((height * img.width) / img.height);
    const bounds = new L.LatLngBounds(
      map.layerPointToLatLng(L.point(0, 0)),
      map.layerPointToLatLng(L.point(width, height))
    );
    L.imageOverlay(img, bounds).addTo(map);
    map.setMaxBounds(bounds);

    map.on('drag', () => {
      map.panInsideBounds(bounds, { animate: false });
    });

    const duration = (20 * width) / height;

    let dir = 'left';
    const scrollAnimation = () => {
      dir = dir === 'right' ? 'left' : 'right';
      const x =
        dir === 'right'
          ? Math.round(width - this.specialWebcam.offsetWidth / 2) - 1
          : Math.round(this.specialWebcam.offsetWidth / 2) + 1;

      const center = map.layerPointToLatLng(L.point(x, Math.round(height / 2)));

      map.setView(center, map.getZoom(), {
        animate: true,
        pan: {
          duration,
          easeLinearity: 1,
        },
      });
    };
    map.addEventListener('moveend', scrollAnimation);
    scrollAnimation();

    const stopScrollAnimation = () => {
      map.removeEventListener('moveend', scrollAnimation);
      map.removeEventListener('click', stopScrollAnimation);
      map.removeEventListener('dragstart', stopScrollAnimation);
      map.removeEventListener('zoom', stopScrollAnimation);
      map.stop();
    };

    map.on('click', stopScrollAnimation);
    map.on('dragstart', stopScrollAnimation);
    map.on('zoom', stopScrollAnimation);
  }

  renderPanorama() {
    const webcam = this.webcams[this.webcamIndex];
    const { imageObj: image } = webcam;

    this.container.classList.add(this.bem('webcam-container', 'loading'));
    this.specialWebcam = document.createElement('div');
    this.container.appendChild(this.specialWebcam);
    this.specialWebcam.style.height = `${Math.min(
      this.maxPanoHeight,
      image.height
    )}px`;
    this.specialWebcam.style.width = '100%';
    this.container.classList.remove(this.bem('webcam-container', 'loading'));

    this.initLeaflet(image);
  }

  imageIsPanoramic(image) {
    return (
      image.width / image.height >= 2 &&
      (image.height <= this.maxPanoHeight ||
        (image.height > this.maxPanoHeight &&
          (image.width * this.maxPanoHeight) /
            image.height /
            this.container.offsetWidth >=
            1.5))
    );
  }

  renderSnoweye() {
    const webcam = this.webcams[this.webcamIndex];
    const { imageObj: image } = webcam;

    if (this.imageIsPanoramic(image)) {
      this.renderPanorama();
    } else {
      this.renderRegularPhoto();
    }
  }

  renderWindy() {
    const webcam = this.webcams[this.webcamIndex];

    const container = document.createElement('div');
    container.style.width = '100%';

    const player = document.createElement('a');
    player.name = 'windy-webcam-timelapse-player';
    player.dataset.id = webcam.windy_id;
    player.play = 'day';
    player.href = `https://windy.com/webcams/${webcam.windy_id}`;
    player.target = '_blank';
    player.innerText = webcam.title;

    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.type = 'text/javascript';
    scriptTag.src =
      'https://webcams.windy.com/webcams/public/embed/script/player.js';

    container.appendChild(player);
    container.appendChild(scriptTag);

    this.specialWebcam = container;
    this.container.appendChild(container);
  }

  selectWebcam(index) {
    this.webcamIndex = index;
    this.updateTitle();
    this.updateCredit();

    this.resetContainer();

    const webcam = this.webcams[this.webcamIndex];
    if (webcam.special) {
      this.renderSpecial();
    } else if (webcam.snoweye) {
      this.renderSnoweye();
    } else if (webcam.windy) {
      this.renderWindy();
    } else if (webcam.snapshots) {
      this.renderWebcamArchive();
    } else {
      this.renderRegularPhoto();
    }
  }

  scrollIntoViewIfNeeded() {
    const bounding = this.container.getBoundingClientRect();

    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      return;
    }

    this.container.scrollIntoView({ behavior: 'smooth' });
  }

  renderWebcamList() {
    const nearbyWebcams = this.el.querySelector(
      this.bemWithDot('nearby-webcams')
    );

    if (this.webcams.length === 1) {
      nearbyWebcams.classList.add(this.bem('nearby-webcams', 'hidden'));
      return;
    }
    nearbyWebcams.classList.remove(this.bem('nearby-webcams', 'hidden'));

    const list = this.el.querySelector(this.bemWithDot('nearby-list'));

    const webcamsCount = this.el.querySelector(
      this.bemWithDot('other-webcams-count')
    );
    webcamsCount.innerText = this.webcams.length - 1;

    const template = list.querySelector(this.bemWithDot('nearby-list-item'));

    list.innerHTML = '';

    this.webcams.forEach((webcam, index) => {
      const item = template.cloneNode(true);

      if (index === this.webcamIndex) {
        item.classList.add(this.bem('nearby-list-item', 'current'));
      }

      if (webcam.snapshots) {
        item.classList.add(this.bem('nearby-list-item', 'archive'));
      }

      if (webcam.special && webcam.special.type === 'ivideon') {
        item.classList.add(this.bem('nearby-list-item', 'player'));
      }

      const panoTypes = ['roundshot', 'feratel'];
      if (
        (webcam.special && panoTypes.includes(webcam.special.type)) ||
        (webcam.imageObj && this.imageIsPanoramic(webcam.imageObj))
      ) {
        item.classList.add(this.bem('nearby-list-item', 'panorama'));
      }

      const providerEl = item.querySelector(
        this.bemWithDot('nearby-list-item-provider')
      );
      providerEl.innerText = webcam.provider;

      const image = item.querySelector(this.bemWithDot('webcam-image'));
      image.src = webcam.thumbnail;

      const titleContainer = item.querySelector(
        this.bemWithDot('nearby-title')
      );

      if (webcam.title) {
        titleContainer.classList.remove(this.bem('title', 'hidden'));
        titleContainer.innerText = webcam.title;
      } else if (webcam.credit) {
        const { label, url } = webcam.credit;
        titleContainer.classList.remove(this.bem('title', 'hidden'));

        const creditLink = titleContainer.querySelector(
          this.bemWithDot('nearby-list-item-provider')
        );
        creditLink.innerText = label;
        creditLink.href = url;
      } else {
        titleContainer.classList.add(this.bem('title', 'hidden'));
      }

      item.addEventListener('click', e => {
        const prev = list.querySelector(
          this.bemWithDot('nearby-list-item', 'current')
        );
        prev.classList.remove(this.bem('nearby-list-item', 'current'));

        item.classList.add(this.bem('nearby-list-item', 'current'));

        this.selectWebcam(index);
        this.scrollIntoViewIfNeeded();
        e.preventDefault();
      });

      list.appendChild(item);
    });
  }

  bem(elem, mod = '') {
    return `${this.className}__${elem}${mod ? `--${mod}` : ''}`;
  }

  bemWithDot(elem, mod = '') {
    return `.${this.bem(elem, mod)}`;
  }
}

export default function resortWebcams() {
  const className = 'resort-webcams';

  Array.from(document.querySelectorAll(`.${className}`)).forEach(el => {
    const gallery = new ResortWebcams(el, className, window.FCGON.webcams);
    gallery.init();
  });
}
