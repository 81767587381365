import 'promise-polyfill/src/polyfill';

import 'core-js/features/object/assign';
import 'core-js/features/object/is';
import 'core-js/features/object/values';
import 'core-js/features/object/entries';
import 'core-js/features/object/from-entries';
import 'core-js/features/object/get-own-property-descriptors';

import 'core-js/features/array/from';
import 'core-js/features/array/of';
import 'core-js/features/array/copy-within';
import 'core-js/features/array/fill';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import 'core-js/features/array/includes';
import 'core-js/features/array/keys';
import 'core-js/features/array/values';

import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';

import 'core-js/features/url-search-params';

import 'whatwg-fetch';

import closest from './polyfills/closest';
import dataset from './polyfills/dataset';
import remove from './polyfills/remove';
import append from './polyfills/append';
import prepend from './polyfills/prepend';
import replaceWith from './polyfills/replace-with';
import requestIdleCallbackPolyfill from './polyfills/request-idle-callback';

export default function initPolyfills() {
  closest();
  dataset();
  remove();
  append();
  prepend();
  replaceWith();
  requestIdleCallbackPolyfill();
}
