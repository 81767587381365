/* eslint-disable no-console */
import { useStore } from '@nanostores/preact'
import { FormState, FORM_ERROR } from 'final-form'
import { Form, Field, FormSpy, FormRenderProps } from 'react-final-form'
import classNames from 'utils/preact/class-names'

import { send } from './services/backend'
import { checkConfirmationCode } from './services/validation'
import { profile } from './store'
import { useTranslation } from './translation'

import css from './style.module.scss'

type FormValues = { confirmationCode: string }

function ResendCodeForm(props: { token: string }) {
  const t = useTranslation()

  return (
    <Form
      onSubmit={() =>
        send<{}>({
          url: '/users/resend_confirmation_email',
          method: 'post',
          token: props.token,
          body: JSON.stringify({})
        }).then((result) => {
          if (result.success) return undefined
          if (result.error) return { [FORM_ERROR]: result.error }
          return result.errors
        })
      }
    >
      {({
        handleSubmit,
        submitSucceeded,
        hasSubmitErrors,
        submitErrors,
        submitError,
        submitting
      }: FormRenderProps & FormState<{}>) => (
        <form action="/users/resend_confirmation_email" method="post" onSubmit={handleSubmit}>
          <p
            className={classNames({
              [css['text-regular']]: true,
              [css['text-center']]: true
            })}
          >
            {t('did_not_get_the_message')}{' '}
            <button
              type="submit"
              className={classNames({
                [css['link-button']]: true,
                [css['font-regular']]: true,
                [css['text-highlight']]: true,
                [css['is-loading']]: submitting
              })}
            >
              {t('send_again')}
            </button>
          </p>
          {submitSucceeded && (
            <p
              className={classNames({
                [css['text-medium']]: true,
                [css['text-center']]: true,
                [css['success-highlight']]: true
              })}
            >
              {t('code_sent')}
            </p>
          )}
          {hasSubmitErrors && (
            <p
              className={classNames({
                [css['text-medium']]: true,
                [css['text-center']]: true,
                [css['error-highlight']]: true
              })}
            >
              {submitErrors.base || submitError}
            </p>
          )}
        </form>
      )}
    </Form>
  )
}

export function EmailVerify(props: {
  token: string
  incentiveContainer?: boolean
  onVerify: () => void
  onEmailUpdate: () => void
}) {
  const t = useTranslation()
  const { email } = useStore(profile)

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: !props.incentiveContainer,
          [css['title-incentive']]: props.incentiveContainer,
          [css['spacer-bottom']]: true
        })}
      >
        {t('verify_your_email')}
      </h2>
      <p
        className={classNames({
          [css['text-regular']]: props.incentiveContainer,
          [css['text-medium']]: !props.incentiveContainer
        })}
      >
        {t('copy_code')} <span className={css['font-black']}>{email}</span>
      </p>
      <Form
        onSubmit={(values: FormValues): Promise<Object | undefined> =>
          send<FormValues>({
            url: '/users/confirm_email_with_code',
            method: 'post',
            token: props.token,
            body: JSON.stringify(values)
          }).then((result) => {
            if (result.success) return undefined
            if (result.error) return { [FORM_ERROR]: result.error }
            return result.errors
          })
        }
      >
        {({
          handleSubmit,
          hasSubmitErrors,
          submitting,
          submitError
        }: FormRenderProps & FormState<FormValues>) => (
          <form action="/users/confirm_email_with_code" method="post" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ submitSucceeded: true }}
              onChange={({ submitSucceeded }: { submitSucceeded: boolean }) => {
                if (submitSucceeded) {
                  profile.setKey('reloadRequired', true)
                  props.onVerify()
                }
              }}
            />
            <Field
              name="confirmation_code"
              validate={(value) => checkConfirmationCode(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="confirmationCode"
                >
                  <div>{t('confirmation_code')}</div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="confirmationCode"
                      type="text"
                      autoComplete="one-time-code"
                      placeholder={t('enter_code')}
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                      onFocus={(event) => {
                        try {
                          ;(event.target as HTMLInputElement).scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'center'
                          })
                        } catch (error) {
                          // eslint-disable-next-line no-console
                          console.error(
                            'failed while scrolling into confirmation code field',
                            error
                          )
                        }
                      }}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />
            <ResendCodeForm token={props.token} />
            <button
              className={classNames({
                [css.submit]: true,
                [css['is-loading']]: submitting,
                [css['spacer-bottom']]: true
              })}
              type="submit"
            >
              {t('verify')}
            </button>

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['text-center']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitError}
              </p>
            )}
          </form>
        )}
      </Form>
      <p
        className={classNames({
          [css['text-regular']]: true,
          [css['text-center']]: true
        })}
      >
        {t('wrong_email_address')}
        <div>
          <button
            type="button"
            className={classNames({
              [css['link-button']]: true,
              [css['font-regular']]: true,
              [css['text-highlight']]: true
            })}
            onClick={props.onEmailUpdate}
          >
            {t('click_to_update')}
          </button>
        </div>
      </p>
    </div>
  )
}
