import incentiveDimensions from './incentive-dimensions';

import './style.scss';

export default function forecastTable() {
  const container = document.getElementById('forecast-table');
  if (!container) return null;

  return import(/* webpackChunkName: "forecast-table" */ './init').then(
    ({ default: initTableController }) => {
      initTableController(container);
    }
  );
}
