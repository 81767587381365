import { useMemo, useRef } from 'preact/hooks'
import { FormState, FORM_ERROR } from 'final-form'
import { Form, Field, FormSpy, FormRenderProps } from 'react-final-form'
import classNames from 'utils/preact/class-names'

import { checkPassword, checkPasswordConfirm } from './services/validation'
import { useTranslation } from './translation'
import { FocusFirstError } from './focus-first-error'
import css from './style.module.scss'

interface FormValues {
  password: string
  passwordConfirm: string
}

export function PasswordUpdate({
  token,
  incentiveContainer,
  onUpdate
}: {
  token: string
  incentiveContainer?: boolean
  onUpdate: () => void
}) {
  const t = useTranslation()
  const formRef = useRef<HTMLFormElement>(null)
  const decorator = useMemo(() => FocusFirstError(formRef), [])

  const resetToken = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return params.get('token')
  }, [])

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: !incentiveContainer,
          [css['title-incentive']]: incentiveContainer,
          [css['spacer-bottom']]: true
        })}
      >
        {t('your_password_has_been_reset')}
      </h2>

      <Form
        decorators={[decorator]}
        onSubmit={(values: FormValues): Promise<Object | undefined> =>
          fetch('/sign_up/password', {
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRF-Token': token
            },
            credentials: 'include',
            body: JSON.stringify({ user: values, password_reset_token: resetToken })
          })
            .then((res) => {
              if (!res.ok) {
                if (res.status !== 422 && res.status !== 401) {
                  return {
                    [FORM_ERROR]: `${res.status}: ${res.statusText}`
                  }
                }
              }

              return res.json()
            })
            .then((res) => {
              if (res.success) return undefined
              if (res.errors) return res.errors
              return res
            })
            .catch((err: Error) => ({
              [FORM_ERROR]: err.message
            }))
        }
      >
        {({
          handleSubmit,
          pristine,
          submitting,
          submitError,
          submitErrors,
          hasSubmitErrors,
          hasValidationErrors,
          form
        }: FormRenderProps & FormState<FormValues>) => (
          <form action="/users" method="post" onSubmit={handleSubmit} ref={formRef}>
            <FormSpy
              subscription={{ submitSucceeded: true }}
              onChange={({ submitSucceeded }: { submitSucceeded: boolean }) => {
                if (submitSucceeded) onUpdate()
              }}
            />

            <Field
              name="password"
              validate={(value: string) => checkPassword(t, value)}
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="password"
                >
                  <div>{t('password')}</div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="password"
                      type="password"
                      autoComplete="new-password"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />
            <Field
              name="passwordConfirm"
              validate={(value: string, { password }: FormValues) =>
                checkPasswordConfirm(t, value, password)
              }
              render={({ input, meta }) => (
                <label
                  className={classNames({
                    [css.label]: true,
                    [css['is-valid']]: meta.touched && meta.valid,
                    [css['is-invalid']]: meta.touched && meta.invalid
                  })}
                  htmlFor="passwordConfirm"
                >
                  <div>{t('repeat_password')}</div>
                  <div
                    className={classNames({
                      [css['input-wrapper']]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                  >
                    <input
                      {...input}
                      id="passwordConfirm"
                      type="password"
                      autoComplete="new-password"
                      className={classNames({
                        [css.input]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    />
                  </div>
                  <div className={css.error}>
                    {(meta.touched && meta.error) || meta.submitError}
                  </div>
                </label>
              )}
            />

            {hasSubmitErrors && (
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['text-center']]: true,
                  [css['error-highlight']]: true
                })}
              >
                {submitErrors.base || submitError}
              </p>
            )}
            <div className={css['spacer-bottom']} />
            <button
              className={classNames({
                [css.submit]: true,
                [css['is-loading']]: submitting
              })}
              type="submit"
              disabled={pristine || hasValidationErrors}
            >
              {t('continue')}
            </button>
          </form>
        )}
      </Form>
    </div>
  )
}
