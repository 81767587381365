function ReplaceWithPolyfill() {
  const parent = this.parentNode;
  let i = arguments.length;
  let currentNode;

  if (!parent) return;
  if (!i)
    // if there are no arguments
    parent.removeChild(this);
  /* eslint-disable-next-line no-plusplus */
  while (i--) {
    // i-- decrements i and returns the value of i before the decrement
    /* eslint-disable-next-line prefer-rest-params */
    currentNode = arguments[i];
    if (typeof currentNode !== 'object') {
      currentNode = this.ownerDocument.createTextNode(currentNode);
    } else if (currentNode.parentNode) {
      currentNode.parentNode.removeChild(currentNode);
    }
    // the value of "i" below is after the decrement
    if (!i)
      // if currentNode is the first argument (currentNode === arguments[0])
      parent.replaceChild(currentNode, this);
    // if currentNode isn't the first
    else parent.insertBefore(this.previousSibling, currentNode);
  }
}

export default function applyReplaceWith() {
  if (!Element.prototype.replaceWith)
    Element.prototype.replaceWith = ReplaceWithPolyfill;
  if (!CharacterData.prototype.replaceWith)
    CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
  if (!DocumentType.prototype.replaceWith)
    DocumentType.prototype.replaceWith = ReplaceWithPolyfill;
}
