import { useStore } from '@nanostores/preact'
import classNames from 'utils/preact/class-names'

import { profile } from './store'
import { useTranslation } from './translation'
import { Currency } from './support'
import css from './style.module.scss'

export function AlreadySubscribed({
  currencies,
  onClose
}: {
  currencies: Currency[]
  onClose: () => void
}) {
  const t = useTranslation()
  const { paymentProfile, premiumExpires } = useStore(profile)

  let content: string
  if (paymentProfile) {
    const { prefix } = currencies.find(
      (c) => c.acronym === paymentProfile.subscription_currency.toUpperCase()
    )
    const units = paymentProfile.subscription_unit_amount / 100

    content = `Your card ending ${paymentProfile.card_last_4} is already contributing ${prefix}${units} monthly`
  } else {
    try {
      const date = new Intl.DateTimeFormat().format(new Date(premiumExpires))
      content = `Your subscription ends ${date}`
    } catch (error) {
      content = 'Your subscription ends soon'
    }
  }

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: true,
          [css['spacer-bottom']]: true
        })}
      >
        {t('youre_already_awesome')}
      </h2>
      <p
        className={classNames({
          [css['text-small']]: true,
          [css['font-regular']]: true
        })}
      >
        {' '}
        {t('thanks_for_supporting')}
      </p>
      <div
        className={classNames({
          [css['text-regular']]: true,
          [css['font-regular']]: true,
          [css['notice-container']]: true
        })}
      >
        {content}
      </div>
      <button type="button" className={css.submit} onClick={onClose}>
        {t('close')}
      </button>
      <p
        className={classNames({
          [css['text-tiny']]: true,
          [css['font-italic']]: true,
          [css['text-center']]: true
        })}
      >
        {t('cancel_anytime')}
      </p>
    </div>
  )
}
