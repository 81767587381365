import { BaseComponent, BElement } from 'utils/base-component'
import { format12Hr, formatDelta } from 'utils/time-format'
import clock from 'utils/clock'
import tides from 'utils/tide/tides'

export class TidesCountdown extends BaseComponent {
  static cssClass = 'tides-countdown'

  highCountdown: BElement
  highTime: BElement
  lowCountdown: BElement
  lowTime: BElement
  localTime: BElement

  init() {
    this.highCountdown = this.element('high-countdown')
    this.highTime = this.element('high-time')
    this.lowCountdown = this.element('low-countdown')
    this.lowTime = this.element('low-time')
    this.localTime = this.element('local-time')

    this.tick()
  }

  tick() {
    const now = clock.currentTimestamp()

    const nextHighTide = tides.nextHighTide(now)
    if (nextHighTide) {
      const { timestamp } = nextHighTide
      const timeLocal = clock.timestampToLocal(timestamp)
      this.highCountdown.setHTML(formatDelta(timestamp - now))
      this.highTime.setHTML(format12Hr(timeLocal, false))
    }

    const nextLowTide = tides.nextLowTide(now)
    if (nextLowTide) {
      const { timestamp } = nextLowTide
      const timeLocal = clock.timestampToLocal(timestamp)
      this.lowCountdown.setHTML(formatDelta(timestamp - now))
      this.lowTime.setHTML(format12Hr(timeLocal, false))
    }

    if (this.localTime) {
      const timeLocal = clock.timestampToLocal(now)
      this.localTime.setHTML(format12Hr(timeLocal))
    }

    setTimeout(() => this.tick(), 1000)
  }
}
