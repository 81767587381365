import { createContext, ComponentChildren } from 'preact'
import { useCallback, useContext } from 'preact/hooks'

export const TranslationContext = createContext<{ [key: string]: string }>(null)

export function TranslationProvider({
  definitions,
  children
}: {
  definitions: { [key: string]: string }
  children: ComponentChildren
}) {
  return <TranslationContext.Provider value={definitions}>{children}</TranslationContext.Provider>
}

export function useTranslation() {
  const translation = useContext(TranslationContext)
  return useCallback(
    (key: string, ...replacers: ((v: string) => string)[]) => {
      let value = translation[key]
      if (!value) return key
      if (!replacers.length) return value

      replacers.forEach((replacer) => {
        const result = value.match(/`([^`]+)`/)
        value = value.replace(result[0], replacer(result[1]))
      })
      return value
    },
    [translation]
  )
}
