import classNames from 'utils/preact/class-names'
import css from './style.module.scss'
import { useTranslation } from './translation'
import surfImageJPG from './static/surf_image_2.jpg'
import surfImageWEBP from './static/surf_image_2.webp'

export function WelcomePaid({
  incentiveContainer,
  onClose
}: {
  incentiveContainer?: boolean
  onClose: () => void
}) {
  const t = useTranslation()
  return (
    <div className={classNames({ [css['split-container']]: !incentiveContainer })}>
      <div className={css.content}>
        <h2
          className={classNames({
            [css.title]: !incentiveContainer,
            [css['title-incentive']]: incentiveContainer,
            [css['spacer-bottom']]: true
          })}
        >
          {t('thanks_for_supporting')}
        </h2>
        <p
          className={classNames({
            [css['text-regular']]: true,
            [css['font-regular']]: true,
            [css['spacer-bottom']]: true
          })}
        >
          {t('you_now_get')}
        </p>
        <p
          className={classNames({
            [css['text-regular']]: true,
            [css['font-medium']]: true,
            [css['spacer-bottom']]: true
          })}
        >
          {t('cancel_anytime')}
        </p>
        <button type="button" className={css.submit} onClick={onClose}>
          {t('close')}
        </button>
      </div>
      <div
        className={classNames({
          [css['flex-grow']]: !incentiveContainer,
          [css['incentive-hidden']]: incentiveContainer
        })}
      >
        <picture>
          <source srcSet={surfImageWEBP} type="image/webp" />
          <source srcSet={surfImageJPG} type="image/jpeg" />
          <img
            src={surfImageJPG}
            alt="Surfer"
            className={classNames({
              [css['large-up-shown']]: true,
              [css['surf-image']]: true
            })}
          />
        </picture>
      </div>
    </div>
  )
}
