/* eslint-disable max-classes-per-file */
import { render } from 'preact'
import { BaseComponent } from 'utils/base-component'
import { Modal } from './modal'
import { Incentive as IncentiveApp } from './incentive'
import { Pages } from './pages'

const meta = document.querySelector('meta[name=csrf-token]') as HTMLMetaElement
const token = meta ? meta.content : ''

declare global {
  interface Window {
    sendSignUpEventToHotjar(): any
  }
}

export class Incentive extends BaseComponent {
  static cssClass = 'js-incentive'

  init() {
    this.mount()
  }

  private mount() {
    render(
      <IncentiveApp
        token={token}
        onClose={() => {
          render(null, this.root)
          this.root.remove()
        }}
        onReload={() => {
          render(null, this.root)
          this.mount()
        }}
        incentiveContainer
      />,
      this.root
    )
  }
}

export class SignUp extends BaseComponent {
  static cssClass = 'signup'

  init() {
    const signUpEls = document.querySelectorAll('.signup-open, .js-signup-modal')
    Array.from(signUpEls).forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault()

        let source: string
        const button: HTMLElement = (event.target as HTMLElement).closest('a,button')
        if (button != null) {
          source = button.dataset.userSource
        }

        this.mount(Pages.AuthorizationPage, { freeAvailable: true, source })
      })
    })

    const signUpPaidEls = document.querySelectorAll('.js-signup-paid-modal')
    Array.from(signUpPaidEls).forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault()

        let source: string
        const button: HTMLElement = (event.target as HTMLElement).closest('a,button')
        if (button != null) {
          source = button.dataset.userSource
        }

        this.mount(Pages.AuthorizationPage, { freeAvailable: false, source })
      })
    })

    const signInEls = document.querySelectorAll('.js-login-modal')
    Array.from(signInEls).forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault()

        let source: string
        const button: HTMLElement = (event.target as HTMLElement).closest('a,button')
        if (button != null) {
          source = button.dataset.userSource
        }

        this.mount(Pages.AuthenticationSubscribePage, { freeAvailable: true, source })
      })
    })

    if (window.location.pathname.startsWith('/sign_up/password/edit')) {
      this.mount(Pages.PasswordUpdatePage)
    } else if (window.location.pathname.startsWith('/sign_up')) {
      this.mount(Pages.AuthorizationPage, { freeAvailable: true, source: 'sign_up_page' })
    }
  }

  private mount(
    action: Pages,
    props: { freeAvailable: boolean; source?: string } = {
      freeAvailable: true
    }
  ) {
    if (typeof window.sendSignUpEventToHotjar === 'function') window.sendSignUpEventToHotjar()

    render(
      <Modal
        action={action}
        source={props.source}
        freeAvailable={props.freeAvailable}
        onClose={() => this.onClose()}
        onReload={(a: Pages) => this.onReload(a)}
      />,
      this.root
    )

    document.body.style.setProperty('overflow', 'hidden')
    document.body.appendChild(this.root)
  }

  private onClose() {
    render(null, this.root)
    document.body.style.removeProperty('overflow')
    document.body.removeChild(this.root)
  }

  private onReload(action: Pages) {
    this.onClose()
    this.mount(action)
  }
}
