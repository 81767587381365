export function checkEmail(t: (s: string) => string, email: string | undefined) {
  if (!email) return t('required')
  if (!email.includes('@') || !email.includes('.')) return t('invalid_email')
  return null
}

export function checkPassword(t: (s: string) => string, password: string | undefined) {
  if (!password) return t('required')
  if (password.length < 6) return t('invalid_password_length')
  return null
}

export function checkPasswordConfirm(
  t: (s: string) => string,
  passwordConfirm: string | undefined,
  password: string | undefined
) {
  if (!passwordConfirm) return t('required')
  if (password !== passwordConfirm) return t('invalid_password_match')
  return null
}

export function checkConfirmationCode(t: (s: string) => string, code: string | undefined) {
  if (!code) return t('required')
  return null
}
