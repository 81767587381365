import './style.scss';

export default function langSelector() {
  const els = Array.from(document.querySelectorAll('.js-lang-selector'));

  els.forEach(el => {
    const select = el.querySelector('select');
    const langs = Array.from(select.options).map(opt => opt.value);

    select.addEventListener('change', () => {
      let url = document.location.href;
      const newLang = select.value;

      langs.forEach(lang => el.classList.remove(lang));
      el.classList.add(newLang);

      const subdomain = newLang === 'en' ? 'www' : newLang;
      url = url.replace(/https?:\/\/(\w\w\.|www\.)?/, `https://${subdomain}.`);
      document.location.href = url;
    });

    select.removeAttribute('disabled');
  });
}
