export default function applyRemove() {
  const arr = [window.Element, window.CharacterData, window.DocumentType];
  const args = [];

  arr.forEach(item => {
    if (item) {
      args.push(item.prototype);
    }
  });

  // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
  args.forEach(item => {
    if (Object.hasOwnProperty.call(item, 'remove')) {
      return;
    }

    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      },
    });
  });
}
