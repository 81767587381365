import classNames from 'utils/preact/class-names'

import css from './style.module.scss'
import check from './static/check.svg'
import { useTranslation } from './translation'

export function PaymentSuccesful({ onNext }: { onNext: () => void }) {
  const t = useTranslation()

  return (
    <div className={css.content}>
      <div className={css['check-icon']}>
        <img src={check} alt="Check icon" width={40} height={46} />
      </div>
      <h2
        className={classNames({
          [css.title]: true,
          [css['font-medium']]: true,
          [css['text-center']]: true,
          [css['spacer-bottom']]: true
        })}
      >
        {t('payment_successful')}
      </h2>
      <button
        className={classNames({
          [css.submit]: true,
          [css['spacer-bottom']]: true
        })}
        type="button"
        onClick={onNext}
      >
        {t('next')}
      </button>
    </div>
  )
}
