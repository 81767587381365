import classNames from 'utils/preact/class-names'
import css from './style.module.scss'

export function Loader() {
  return (
    <>
      <div className={css.content} />
      <h1
        className={classNames({
          [css.title]: true,
          [css['text-center']]: true,
          [css['spacer-bottom']]: true,
          [css['text-highlight']]: true
        })}
      >
        Surf-forecast.com
      </h1>
      <div className={css.loader} />
      <div
        className={classNames({
          [css.subtitle]: true,
          [css['text-center']]: true
        })}
      >
        Please stand by...
      </div>
      <div className={css.content} />
    </>
  )
}
