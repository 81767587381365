import { map } from 'nanostores'
import { ProductDuration, PaymentProfile } from './support'

export type SubscriptionValue = 'free' | ProductDuration

export interface Profile {
  newAccountCreated: boolean
  reloadRequired: boolean
  emailVerified: boolean
  authorized: boolean
  premium: boolean
  source: string

  subscription: SubscriptionValue
  countryIso: string
  email: string
  password: string
  passwordConfirm: string

  paymentProfile: PaymentProfile
  premiumExpires: number | null
}

export const profile = map<Profile>({
  newAccountCreated: false,
  reloadRequired: false,
  emailVerified: false,
  authorized: false,
  premium: false,
  source: '',

  subscription: 'monthly',
  countryIso: '',
  email: '',
  password: '',
  passwordConfirm: '',

  paymentProfile: null,
  premiumExpires: null
})
